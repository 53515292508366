import React, { useEffect, useState } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar/Sidebar";

// helper
import { structure } from "../../constant/sidebarStructure";

// pages
import Chargebacks from "../../pages/chargeBacks/Chargebacks";
import ShortageClaims from "../../pages/shortageClaims/ShortageClaims";
import GoalsAndPerformance from "../../pages/goalsAndPerformance/Sales.js";
import ShippedCOGS from "../../pages/shippedCOGS/ShippedCOGS";
import SellerCentral from "../../pages/sellerCentral/SellerCentral";
import orderMetrics from "../../pages/orderMetrics/orderMetrics";
import orderMetricsASIN from "../../pages/orderMetrics/orderMetricsASIN";
import FBAInventoryRepl from "../../pages/FBAInteventoryReplenishment/FBAInventoryRepl";
import GeographicInsights from "../../pages/geographicInsights/GeographicInsights";
import SalesPerformanceTrends from "../../pages/salesPerformanceTrends/SalesPerformanceTrends";
import ShareOfVoice from "../../pages/shareOfVoice/ShareOfVoice";
import BrandSegments from "../../pages/brandSegmentByDay/brandSegmentByDay";
import KeywordsByASIN from "../../pages/keywordsByASIN/KeywordsByASIN";
import MerchantListingsReport from "../../pages/merchantListingsReports/merchantListingsReport";
import SalesAndRank from "../../pages/salesAndRank/salesAndRank";
import competitionSalesByASIN from "../../pages/competitionSalesByASIN/competitionSalesByASIN";
import ProductsAndReviews from "../../pages/productsAndReviews/productsAndReviews";
import ProductsAndReviewsAll from "../../pages/productsAndReviewsAll/productsAndReviewsAll";
import PriceWar from "../../pages/priceWar/priceWar";
import CompetitionBenchmarking from "../../pages/competitionBenchmarking/CompetitionBenchmarking";
// import CampaignsPerformance from "../../pages/campaignsPerformance/CampaignsPerformance";
import ProductsNotServing from "../../pages/productsNotServing/ProductsNotServing";
import CampaignsOutOfBudget from "../../pages/campaignsOutOfBudget/CampaignsOutOfBudget";
import FusionAds from "../../pages/fusionAds/FusionAds";
import ReachTrend from "../../pages/reachTrend/reachTrend";
import SearchQueryPerformance from "../../pages/searchQueryPerformance/SearchQueryPerformance";
import InvoicesReconciliation from "../../pages/invoicesReconcilation/InvoicesReconciliation";
import InventoryManagement from "../../pages/inventoryManagement/InventoryManagement";
// import AvailabilityOrBuyability from "../../pages/availabilityOrBuyability/AvailabilityOrBuyability";
import DetailedPages from "../../pages/detailedPages/DetailedPages";
import APlusPage from "../../pages/aPlusPage/APlusPage";
import CopywritingOrSEO from "../../pages/copywritingOrSEO/CopywritingOrSEO";
import AddUser from "../../pages/addUser/AddUser";
import ListUsers from "../../pages/listUsers/ListUsers";
import UploadProductDetails from "../../pages/uploadProductDetails/UploadProductDetails";
import UploadAsinsForHiResImages from "../../pages/uploadAsinsForHiResImages/UploadAsinsForHiResImages";
import ASINSKUMapping from "../../pages/ASIN-SKU/ASINSKUMapping";
import CampaignsPerformance from "../../pages/campaignsPerformance/CampaignsPerformance";
import SellerInvoiceReconciliation from "../../pages/sellerInvoiceReconciliation/SellerInvoiceReconciliation";
import EventNotifications from "../../pages/eventNotifications/eventNotification";
import threePReport from "../../pages/threePReport/threep";
import MapViolaters from "../../pages/mapViolaters/mapViolaters";
import orderMetricsHourly from "../../pages/orderMetricsHourly/orderMetricsHourly";
import UploadHistoricData from "../../pages/uploadHistoricData/UploadHistoricData";
import amsCampaigns from "../../pages/amsCampaigns/amsCampaigns";
import keyMetricsVC from "../../pages/keyMetricsVC/keyMetricsVC";
import keyMetricsVCGraph from "../../pages/keyMetricsVC/keyMetricsVCGraph";
import cronsManagement from "../../pages/cronsManagement/cronsManagement";
import UserActivity from "../../pages/userActivity/UserActivity"
import BrandFinder from "../../pages/brandFinder/BrandFinder"
import BlockedDomains from "../../pages/blockDomains/BlockedDomains"
import ContactFinder from "../../pages/contactFinder/ContactFinder"
import ListBrands from "../../pages/listBrands/ListBrands"
import ListContacts from "../../pages/listContacts/ListContacts"
import SearchContacts from "../../pages/searchContacts/SearchContacts"
import ListEmptyDomains from "../../pages/listEmptyDomains/ListEmptyDomains"
import UpdateItem from "../../pages/updateItem/UpdateItem";
import CustomerAccounts from "../../pages/amsProfiles/amsProfiles"
import CategoryScrapping from "../../pages/categoryScrapping/CategoryScrapping"
import CategoryFiles from "../../pages/categoryFiles/CategoryFiles.js"
import BrandFinderGlobal from "../../pages/brandFinderGlobal/BrandFinderGlobal"
import ManageShipments from "../../pages/manageShipments/ManageShipments";
import CreateNewShipment from "../../pages/createNewShipment/CreateNewShipment";
import TransportDetails from "../../pages/transportDetails/TransportDetails";
import EstimateShippingCost from "../../pages/estimateShippingCost/EstimateShippingCost"
import CampaignPerformance from "../../pages/campaignPerformance/campaignPerformance";
import KeywordPerformance from "../../pages/keywordPerformance/keywordPerformance";
import SearchTermPerformance from "../../pages/searchTermPerformance/searchTermPerformance";
import ShipmentAddress from "../../pages/shipmentAddress/shipmentAddress";
import PrintLabelsOrBol from "../../pages/printLabelsOrBol/PrintLabelsOrBol";
import CasePackDetails from "../../pages/casepackDetails/CasePackDetails";
import ShipmentContact from "../../pages/shipmentContact/ShipmentContact";
import SendTrackingNumbers from "../../pages/sendTrackingNumbers/SendTrackingNumbers";
import AmsOnboarding from "../../pages/amsOnboarding/AmsOnboarding";
import ReportsHealth from "../../pages/reportsHealth/reportsHealth";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  fetchAllDates,
} from "../../context/LayoutContext";
import { useVendorState } from "../../context/VendorContext";
import AmsScreen1Page from "../../pages/amsReport/AmsReportScreen1";
import AmsScreen2Page from "../../pages/amsReportScreen2/AmsReportScreen2";
import AmsCreateCampaign from "../../pages/createAMSCampaign/createAMSCampaign";
import VendorSettings from "../../pages/vendorSettings/vendorSettings";
import CatalogManager from "../../pages/catalogManager/catalogManager";
import CohesityNotifications from "../../pages/cohesityNotifications/cohesityNotifications";
import CampaignVersions from "../../pages/campaignVersions/campaignVersions";
import { useUserState } from "../../context/UserContext";
import UnauthorizedUser from "../../pages/unauthorized/unauthorizedUser";
import CatalogItems from "../../pages/catalogItems/CatalogItems";
import CatalogVersions from "../../pages/catalogVersions/catalogVersions";
import StrandedInventory from "../../pages/strandedInventory/StrandedInventory";
import AccountHealth from "../../pages/accountHealth/AccountHealth";
import FbaReimbursements from "../../pages/fbaReimbursements/FbaReimbursements";

import Info from "../Info/Info";
import ConnectSellerCentral from "../../pages/connectSellerCentral/connectSellerCentral";
import DayParting from "../../pages/dayParting/dayParting";
import JsSegment from "../../pages/jsSegment/jsSegment";
import JSProductsAndBrands from "../../pages/jsProductsAndBrands/jsProductsAndBrands";
import TargetPerformance from "../../pages/targetPerformance/targetPerformance";
import BudgetChanges from "../../pages/budgetChanges/BudgetChanges";
import PlacementAnalysis from "../../pages/placementAnalysis/placementAnalysis";
import CatalogActivity from "../../pages/catalogActivity/catalogActivity";
import ASINPerformance from "../../pages/asinPerformance/asinPerformance";
import BcgOverview from "../../pages/bcgOverview/bcgOverview";
import JSProducts from "../../pages/jsProducts/jsProducts";
import CohesityLogs from "../../pages/cohesityLogs/CohesityLogs";
import BCGMatrix from "../../pages/bcgMatrix/bcgMatrix";
import Projections from "../../pages/forcastProjections/forcastProjections";
import HealthCheck from "../../pages/forecastHealth/forecastHealth";
// import BCGMatrixBC from "../../pages/bcgMatrix/bcgMatrix.backup";
// import ProjectionsBC from "../../pages/forcastProjections/forecastProjections.backup";
// import HealthCheckBC from "../../pages/forecastHealth/forecastHealth.backup";
import SegmentProjections from "../../pages/segmentProjections/segmentProjections";
import DapiStatistics from "../../pages/dapiStatistics/DapiStatistics";
import SegmentForecastHealth from "../../pages/segmentForecastHealth/segmentForecastHealth";
import ASINsOnboarding from "../../pages/asinsOnboarding/asinsOnboarding";
import DbArchives from "../../pages/dbArchives/DbArchives";
import CustomerSettings from "../../pages/customerSettings/CustomerSettings";
import UserGroups from "../../pages/userGroups/UserGroups";
import DetailPage from "../../pages/detailPage/DetailPage";
import SearchOrganisations from "../../pages/search Organisations/SearchOrganisations";
import EnrichContacts from "../../pages/enrichContacts/EnrichContacts.js";
import ActionItems from "../../pages/actionItems/actionItems.js";
import KeyOutputMetrics from "../../pages/keyOutputMetrics/KeyOutputMetrics.js";
import KeyInputMetrics from "../../pages/keyInputMetrics/KeyInputMetrics.js";
import OtherKeyMetrics from "../../pages/otherKeyMetrics/OtherKeyMetrics.js";
import ProgressAgainstGoals from "../../pages/progressAgainstGoals/ProgressAgainstGoals.js";
import WbrFinance from "../../pages/wbrFinance/WbrFinance.js";
import WbrOperations from "../../pages/wbrOperations/WbrOperations.js";
import WbrAms from "../../pages/wbrAMS/WbrAms.js";
import ApproveContent from "../../pages/approveContent/ApproveContent";
import UpdateContent from "../../pages/updateContent/UpdateContent";
import ChangelogContent from "../../pages/changelogContent/ChangelogContent";
import AMRules from "../../pages/amRules/amRules";
import AMRuleDetails from "../../pages/amRuleDetails/amRuleDetails";
import DeactivateAccount from "../../pages/deactivateAccount/DeactivateAccount";
import MergeAccount from "../../pages/mergeAccount/MergeAccount";
import GlanceViews from "../../pages/glanceViews/glanceViews.js";
import Conversions from "../../pages/conversions/conversions.js";
import SalesFunnel from "../../pages/salesFunnel/salesFunnel.js";
import WeeklyInsights from "../../pages/weeklyInsights/weeklyInsights.js";
import DomainsLLMValidation from "../../pages/domainsLLMValidation/domainsLLMValidation.js";
import AuditTool from "../../pages/ppcAudit/ppcAudit.js";
import WbrFunnel from "../../pages/wbrFunnel/WbrFunnel.js";
import ProductImages from "../../pages/productImages/ProductImages.js";
import SEOUpdateCheck from "../../pages/seoUpdateCheck/SEOUpdateCheck.js";
import CatalogImageManager from "../../pages/catalogImageManager/catalogImageManager.js";
import CatalogVariations from "../../pages/catalogVariations/CatalogVariations.js";
import AMSForecast from "pages/amsForecast/amsForecast";

function Layout(props) {
  var classes = useStyles();
  // global
  var layoutState = useLayoutState();
  var vendorState = useVendorState();
  var layoutDispatch = useLayoutDispatch();
  let userState = useUserState();
  const [screenType, setScreenType] = useState(["seller", "vendor"]);

  useEffect(() => {
    fetchAllDates(layoutDispatch, vendorState.selected);
  }, [vendorState]);

  useEffect(() => {
    let userData = userState?.userData;
    const {path,subPath}= getPath();
    if (path && userData) {
      let permissionGranted =
        userData?.permissions_module_level[path.pageCode]?.allowed;
      if (subPath && userData)
        permissionGranted =
          userData?.permissions_module_level[path.pageCode]?.allowed &&
          userData?.permissions_module_level[path.pageCode].categories[
            subPath.pageCode
          ];
      if (!permissionGranted) {
        props.history.push({
          pathname: "/app/unauthorize",
          state: { path: path?.label, subPath: subPath?.label },
        });
      }
      setScreenType(subPath?.accountType || path?.accountType || ["seller", "vendor"]);
    }
  }, [vendorState, props?.location?.pathname]);

  const getPath = () => {
    let url = props?.location?.pathname;
    for (const path of structure) {
      if (path.link === url) return { path, subPath: null };
      if (!path.children) continue;
      for (const subPath of path.children) {
        if (subPath.link === url) return { path, subPath };
        if (!subPath.children) continue;
        for (const subPath2 of subPath.children) {
          if (subPath2.link === url) return { path, subPath, subPath2 };
        }
      }
    }
    return false;
  };


  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} screenType={screenType} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/integrationSuccess" component={() => 
              <Info 
                title="Integration Success"
                subtitle="Your integration is successful. Please wait for 24 hours to see the data."
              />} 
            />
            <Route path="/app/integrationFailed" component={() => 
              <Info 
                title="Integration Success"
                subtitle="Your integration is successful. Please wait for 24 hours to see the data."
              />} 
            />
            {/* <Route path="/app/integrationFailed" component={() => 
              <Info 
                title="Integration Failed"
                subtitle="Your integration is failed. Please try again."
              />} 
            /> */}
            <Route path="/app/integration" component={ConnectSellerCentral} 
            />
            <Route
              path="/app/sales/shippedRevenue"
              component={GoalsAndPerformance}
            />
            <Route path="/app/sales/shippedCOGS" component={ShippedCOGS} />
            <Route path="/app/sales/sellerCentral" component={SellerCentral} />
            <Route path="/app/sales/geo" component={GeographicInsights} />
            {/* <Route path="/app/sales/trends" component={SalesPerformanceTrends} /> */}
            <Route path="/app/sc_cb/chargebacks" component={Chargebacks} />
            <Route path="/app/sc_cb/claims" component={ShortageClaims} />
            <Route path="/app/bi/voice" component={ShareOfVoice} />
            <Route path="/app/bi/brandSegments" component={BrandSegments} />
            <Route path="/app/bi/keywordsByASIN" component={KeywordsByASIN} />
            <Route path="/app/bi/bcgMatrix" component={BCGMatrix} />
            <Route path="/app/bi/projections" component={Projections} />
            <Route path="/app/bi/healthCheck" component={HealthCheck} />
            {/* <Route path="/app/bi/bc_bcgMatrix" component={BCGMatrixBC} />
            <Route path="/app/bi/bc_projections" component={ProjectionsBC} />
            <Route path="/app/bi/bc_healthCheck" component={HealthCheckBC} /> */}
            <Route path="/app/bi/segmentProjections" component={SegmentProjections} />
            <Route path="/app/bi/segmentForecastHealth" component={SegmentForecastHealth} />
            <Route
              path="/app/bi/merchantListingsReport"
              component={MerchantListingsReport}
            />
            <Route path="/app/bi/SalesAndRank" component={SalesAndRank} />
            <Route path="/app/bi/priceWar" component={PriceWar} />
            <Route
              path="/app/bi/benchmark"
              component={CompetitionBenchmarking}
            />
            <Route
              path="/app/bi/jsProductsAndBrands"
              component={JSProductsAndBrands}
            />
            <Route
              path="/app/bi/segments"
              component={JSProducts}
            />  
            <Route path="/app/adv/cp" component={CampaignsPerformance} />
            <Route path="/app/adv/pnots" component={ProductsNotServing} />
            <Route path="/app/adv/cob" component={CampaignsOutOfBudget} />
            <Route path="/app/adv/sqp" component={SearchQueryPerformance} />
            <Route path="/app/adv/fusionAds" component={FusionAds} />
            <Route
              path="/app/operations/invmgmt"
              component={InventoryManagement}
            />
            <Route path="/app/ams/salesAnalysis" component={AmsScreen1Page} />
            <Route
              path="/app/ams/topPerformingKeywords"
              component={AmsScreen2Page}
            />
            <Route
              path="/app/ams/createCampaign"
              component={AmsCreateCampaign}
            />
            <Route path="/app/ams/vendorSettings" component={VendorSettings} />
            <Route path="/app/ams/dayParting" component={DayParting} />
            <Route
              path="/app/operations/invoicesReconciliation"
              component={InvoicesReconciliation}
            />
            <Route
              path="/app/operations/account-health"
              component={AccountHealth}
            />
            <Route
              path="/app/operations/stranded-inventory"
              component={StrandedInventory}
            />
            <Route
              path="/app/operations/fba-reimbursements"
              component={FbaReimbursements}
            />
            <Route path="/app/ams/amsCampaigns" component={amsCampaigns} />
            {/* <Route path="/app/operations/avaorbuy" component={AvailabilityOrBuyability} /> */}
            <Route path="/app/creative/dp" component={DetailedPages} />
            <Route path="/app/creative/ap" component={APlusPage} />
            <Route path="/app/creative/cpyseo" component={CopywritingOrSEO} />
            <Route path="/app/users/register" component={AddUser} />
            <Route path="/app/users/list" component={ListUsers} />
            <Route
              path="/app/catalog/productDetails"
              component={UploadProductDetails}
            />
            <Route
              path="/app/catalog/productHiResImages"
              component={UploadAsinsForHiResImages}
            />
            <Route
              path="/app/catalog/asinSKUMapping"
              component={ASINSKUMapping}
            />
            <Route
              path="/app/operations/scinvoicereconciliation"
              component={SellerInvoiceReconciliation}
            />
            <Route
              path="/app/sales/orderMetrics/:type?"
              component={orderMetrics}
            />
            <Route
              path="/app/sales/orderMetricsASINs/:type?"
              component={orderMetricsASIN}
            />
            <Route
              path="/app/bi/FBAInventoryRep"
              component={FBAInventoryRepl}
            />
            <Route
              path="/app/bi/competitionSalesASIN"
              component={competitionSalesByASIN}
            />
            <Route
              path="/app/bi/productAndReview/:asin?"
              component={ProductsAndReviews}
            />
            <Route
              path="/app/bi/productRatingAndReviewAll/:asin?"
              component={ProductsAndReviewsAll}
            />
            <Route
              path="/app/bi/notifications"
              component={EventNotifications}
            />
            <Route path="/app/bi/threep" component={threePReport} />
            <Route path="/app/bi/mapViolaters" component={MapViolaters} />
            <Route path="/app/bi/bcgOverview" component={BcgOverview} />
            <Route
              path="/app/sales/orderMetricsHour"
              component={orderMetricsHourly}
            />
            <Route
              path="/app/onboarding/uploadHistoricData"
              component={UploadHistoricData}
            />
            <Route path="/app/sales/keymetricsvc" component={keyMetricsVC} />
            <Route
              path="/app/sales/keymetricsvcgraph"
              component={keyMetricsVCGraph}
            />
            <Route
              path="/app/catalog/cohesity/catalogManager"
              component={CatalogManager}
            />
            <Route
              path="/app/catalog/cohesity/cohesityNotifications"
              component={CohesityNotifications}
            />
            <Route
              path="/app/ams/campaignVersions/:id"
              component={CampaignVersions}
            />
            <Route path="/app/onboarding/cronsManagement" component={cronsManagement} />
            <Route path="/app/onboarding/asins" component={ASINsOnboarding} />
            <Route path="/app/unauthorize" component={UnauthorizedUser} />
            <Route path="/app/users/activity" component={UserActivity} />
            <Route path="/app/prospects/brandFinder" component={BrandFinder} />
            <Route path="/app/prospects/blockedDomains" component={BlockedDomains} />
            <Route path="/app/prospects/contactFinder" component={ContactFinder} />
            <Route path="/app/prospects/listBrands" component={ListBrands} />
            <Route path="/app/prospects/listContacts" component={ListContacts} />
            <Route path="/app/prospects/searchContacts" component={SearchContacts} />
            <Route path="/app/prospects/searchOrganisations" component={SearchOrganisations} />
            <Route path="/app/prospects/enrichContacts" component={EnrichContacts} />
            <Route path="/app/prospects/listEmptyDomains" component={ListEmptyDomains} />
            <Route path="/app/catalog/updateItem" component={UpdateItem} />
            <Route path="/app/ams/customerAccounts" component={CustomerAccounts} />
            <Route path="/app/prospects/brandFinderGlobal" component={BrandFinderGlobal} />
            <Route path="/app/prospects/categoryASINs" component={CategoryScrapping} />
            <Route path="/app/prospects/categoryFiles" component={CategoryFiles} />
            <Route path="/app/catalog/catalogItems" component={CatalogItems} />
            <Route path="/app/catalog/versions" component={CatalogVersions} />
            <Route path="/app/fbaShipment/manageShipments" component={ManageShipments} />
            <Route path="/app/fbaShipment/createNewShipment" component={CreateNewShipment} />
            <Route path="/app/fbaShipment/sendTransportDetails" component={TransportDetails} />
            <Route path="/app/fbaShipment/estimateShippingCost" component={EstimateShippingCost} />
            <Route path="/app/fbaShipment/shipmentAddress" component={ShipmentAddress} />
            <Route path="/app/fbaShipment/printLabelsOrBol" component={PrintLabelsOrBol} />
            <Route path="/app/fbaShipment/casePackDetails" component={CasePackDetails} />
            <Route path="/app/fbaShipment/shipmentContact" component={ShipmentContact} />
            <Route path="/app/fbaShipment/sendTrackingNumbers" component={SendTrackingNumbers} />
            <Route path="/app/bi/jsSegment" component={JsSegment} />
            <Route path="/app/reports/campaignPerformance" component={CampaignPerformance} />
            <Route path="/app/reports/keywordPerformance" component={KeywordPerformance} />
            <Route path="/app/reports/asinPerformance" component={ASINPerformance} />
            <Route path="/app/reports/searchTermPerformance" component={SearchTermPerformance} />
            <Route path="/app/reports/targetPerformance" component={TargetPerformance} />
            <Route path="/app/reports/placementAnalysis" component={PlacementAnalysis} />
            <Route path="/app/reports/budgetChanges" component={BudgetChanges} />
            <Route path="/app/reports/reachTrend" component={ReachTrend} />
            <Route path="/app/catalog/activity" component={CatalogActivity} />
            <Route path="/app/catalog/cohesity" component={CohesityLogs} />
            <Route path="/app/onboarding/ams" component={AmsOnboarding} />
            <Route path="/app/onboarding/dapiStatistics" component={DapiStatistics} />
            <Route path="/app/onboarding/dbArchives" component={DbArchives} />
            <Route path="/app/onboarding/customerSettings" component={CustomerSettings} />
            <Route path="/app/onboarding/reportsHealth" component={ReportsHealth} />
            <Route path="/app/onboarding/userGroups" component={UserGroups} />
            <Route path="/app/catalog/detailPage" component={DetailPage} />
            <Route path="/app/wbr/actionItems" component={ActionItems} />
            <Route path="/app/wbr/keyOutputMetrics" component={KeyOutputMetrics} />
            <Route path="/app/wbr/keyInputMetrics" component={KeyInputMetrics} />
            <Route path="/app/wbr/otherKeyMetrics" component={OtherKeyMetrics} />
            <Route path="/app/wbr/progressAgainstGoals" component={ProgressAgainstGoals} />
            <Route path="/app/wbr/financeMetrics" component={WbrFinance} />
            <Route path="/app/wbr/operationMetrics" component={WbrOperations} />
            <Route path="/app/wbr/ams" component={WbrAms} />
            <Route path="/app/content/approve" component={ApproveContent} />
            <Route path="/app/content/update" component={UpdateContent} />
            <Route path="/app/content/changelog" component={ChangelogContent} />
            <Route path="/app/am/rules" component={AMRules} />
            <Route path="/app/am/details" component={AMRuleDetails} />
            <Route path="/app/onboarding/deactivateAccount" component={DeactivateAccount} />
            <Route path="/app/onboarding/mergeAccount" component={MergeAccount} />
            <Route path="/app/creative/glanceViews" component={GlanceViews} />
            <Route path="/app/creative/conversions" component={Conversions} />
            <Route path="/app/bi/keyMetrics" component={SalesFunnel} />
            <Route path="/app/wbr/weeklyInsights" component={WeeklyInsights} />
            <Route path="/app/prospects/domainsLLMValidation" component={DomainsLLMValidation} />
            <Route path="/app/ams/amsPPCAudit" component={AuditTool} />
            <Route path="/app/wbr/funnel" component={WbrFunnel} />
            <Route path="/app/creative/productImages" component={ProductImages} />
            <Route path="/app/catalog/seoUpdateCheck" component={SEOUpdateCheck} />
            <Route path="/app/catalog/catalogImageManager" component={CatalogImageManager} />
            <Route path="/app/catalog/catalogVariations" component={CatalogVariations} />
            <Route path="/app/ams/forecast" component={AMSForecast} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
