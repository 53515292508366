/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from "react";
import {
    Checkbox,
    Chip,
    Container,
    Grid,
    Box,
    Breadcrumbs,
    Button,
    FormControl,
    InputLabel,
    Link,
    ListItemText,
    MenuItem,
    Typography,
    CircularProgress,
    Backdrop,
    Snackbar,
    Select,
} from "@material-ui/core";
import "date-fns";
import Widget from "../../components/Widget/Widget";
import Alert from '@material-ui/lab/Alert';

//icons
import HistoryIcon from "@material-ui/icons/History";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
import Form from "./components/Form/Form";

//context
import { useVendorState } from "../../context/VendorContext";

import {
  fetch3PReport,
  fetchShareOfVoiceReport,
  generateOrderMetricsData,
  fetchGeographicInsightsReport,
  fetchCampaignReports,
  fetchSalesTrafficReports,
  fetchVendorSalesReport,
  fetchJSSegmentHistory,
  catalogRefresh,
  fetchSearchTermsReport,
  fetchKeywordsReport,
  fetchVendorTrafficReport,
  fetchPromotionPerformanceReport,
  fetchCouponPerformanceReport,
  fetchProductPricingReport,
  fetchVendorNPPMarginReport,
  fetchInventoryReports,
  configureAmRuleDefault,
  pullVendorRealTimeSalesReport,
  pullVendorRealTimeTrafficReport,
  pullVendorRealTimeInventoryReport,
  fetchMbrExport,
  saveAsinsApiConfig,
  saveSegmentsApiConfig,
  saveReviewsAsinsApiConfig,
  uploadS3CatalogFavicon,
  uploadS3JsCompetitorFavicon,
  generateReviews,
  deleteReportCache,
  createReportCache,
  generateCommissionsExport,
} from "../../http/api";

import useStyles from "./styles";
import { CACHE_NAME } from "../../constant/dataCache";

export default function Charts(props) {
    const exportFunctionRef = useRef();
    //global
    const classes = useStyles();
    const [mainTableData, setmainTableData] = useState([]);
    var vendorState = useVendorState();

    // const Alert = React.forwardRef(function Alert(props, ref) {
    //     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    //   });

    const [selectedVendor, setSelectedVendor] = useState([]);
    const [selectedCacheVendor, setSelectedCacheVendor] = useState([]);
    const [selectedReportType, setSelectedReportType] = useState('');
    const [segmentId, setSegmentId] = useState('');
    const [snackBar, handleSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    // local
    const [fullPageLoader, setfullPageLoader] = useState(false);
    const [cacheName, setCacheName] = useState('');

    const generateReport = async (
      vendorCode,
      reportType,
      startDate,
      endDate,
      asinConfig,
    ) => {
      let data;
      try {
        setfullPageLoader(true);
        switch (reportType) {
          case "ordermetricsday":
          case "ordermetricshour":
          case "ordermetricsasinweek":
          case "ordermetricsasinday":
          case "ordermetricsasinhour":
            data = await generateOrderMetricsData(
              vendorCode,
              reportType,
              startDate,
              endDate
            );
            break;
          case "3preport":
            data = await fetch3PReport(vendorCode, startDate, endDate);
            break;
          case "shareofvoice":
            data = await fetchShareOfVoiceReport(vendorCode, startDate, endDate);
            break;
          case "geographicinsights":
            data = await fetchGeographicInsightsReport(
              vendorCode,
              startDate,
              endDate
            );
            break;
          case "campaignreports":
            data = await fetchCampaignReports(vendorCode);
            break;
          case "salestraffic":
            data = await fetchSalesTrafficReports(
              vendorCode,
              startDate,
              endDate
            );
            break;
          case "vendorsalesreport":
            data = await fetchVendorSalesReport(vendorCode, startDate, endDate);
            break;
        case "jssegmentreports":
            data = await fetchJSSegmentHistory(segmentId, startDate, endDate);
            break;
        case "catalogRefresh":
            data = await catalogRefresh(vendorCode);
            break;
        case "searchtermsreport":
            data = await fetchSearchTermsReport();
            break;
        case "keywordsreport":
            data = await fetchKeywordsReport();
            break;
        case "vendortrafficreport":
            data = await fetchVendorTrafficReport(vendorCode, startDate, endDate);
            break;
        case "promotionperformance":
            data = await fetchPromotionPerformanceReport(vendorCode, startDate, endDate);
            break;
        case "couponperformance":
                data = await fetchCouponPerformanceReport(vendorCode, startDate, endDate);
                break;
        case "productpricing":
                data = await fetchProductPricingReport(vendorCode, startDate, endDate);
                break;
        case "vendornppmarginreport":
                data = await fetchVendorNPPMarginReport(vendorCode, startDate, endDate);
                break; 
        case "inventoryreports":
                data = await fetchInventoryReports(vendorCode, startDate, endDate);
                break;
        case "amRulesDefault":
                data = await configureAmRuleDefault(vendorCode);
                break;
        case "realTimeVendorSales":
                data = await pullVendorRealTimeSalesReport(vendorCode);
                break;
        case "realTimeVendorTraffic":
            data = await pullVendorRealTimeTrafficReport(vendorCode);
            break;
        case "realTimeVendorInventory":
            data = await pullVendorRealTimeInventoryReport(vendorCode);
            break;
        case "mbrExport":
            data = await fetchMbrExport(vendorCode);
            break;
        case "commissionsExport":
            data = await generateCommissionsExport(vendorCode, startDate, endDate);
            break;
        case "apiConfigReviewsAsins":
            data = await saveReviewsAsinsApiConfig(vendorCode, asinConfig);
            break;
        case "apiConfigAsins":
            data = await saveAsinsApiConfig(vendorCode);
            break;
        case "apiConfigSegments":
            data = await saveSegmentsApiConfig(vendorCode);
            break;
        case "s3CatalogFavicon":
            data = await uploadS3CatalogFavicon(vendorCode);
            break;
        case "s3JsCompetitorFavicon":
            data = await uploadS3JsCompetitorFavicon(vendorCode);
            break;
        case "generateReviews":
            data = await generateReviews(vendorCode);
            break;
        default:
        }
        handleSnackBar(true);
        setSnackBarMessage(data?.message);
      } catch (err) {
        handleSnackBar(true);
        setSnackBarMessage(err?.message || err);
        console.error(err);
      } finally {
        setfullPageLoader(false);
      }
    };

    return (
        <>
            <Container maxWidth={false}>
                <PageTitle
                    title="Onboarding"
                    breadCrump={
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                color="inherit"
                                className={classes.link}
                            >
                                Onboarding
                            </Link>
                            <Typography className={classes.link} style={{ alignItems: "center", }}>
                                <HistoryIcon color="primary" className={classes.icon} />
                                Historic Data
                            </Typography>
                        </Breadcrumbs>
                    }
                />
                <Grid container spacing={4}>
                    {/* Table is hidden as the data generation requests are not tracked */}
                    <Grid item xs={12} style={{ display: "none" }}>
                        {<Widget
                            upperTitle
                            noBodyPadding
                            bodyClass={classes.tableOverflow}
                            header={
                                <div className={classes.mainChartHeader}>
                                    <Typography
                                        variant="h2"
                                        gutterBottom
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 20,
                                            color: "black",
                                        }}
                                    >
                                        {`Historic Data`}
                                    </Typography>
                                </div>
                            }
                        >
                            <Table ref={exportFunctionRef} tableData={mainTableData} />
                        </Widget>}
                    </Grid>
                    <Grid item xs={12} >
                        <Widget
                            upperTitle
                            noBodyPadding
                            bodyClass={classes.tableOverflow}
                            header={
                                <div className={classes.mainChartHeader}>
                                    <Typography
                                        variant="h2"
                                        gutterBottom
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 20,
                                            color: "black",
                                        }}
                                    >
                                        {`Cache`}
                                    </Typography>
                                </div>
                            }
                        >
                            <div style={{ display: "flex", gap: "16px", padding: "16px"}}>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                    <InputLabel>
                                        Select Vendor
                                    </InputLabel>
                                    <Select
                                        value={selectedCacheVendor}
                                        renderValue={() => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: "4px" }}>
                                                {selectedCacheVendor.map((value) => (
                                                    <Chip
                                                        size="small"
                                                        variant="outlined"
                                                        key={value}
                                                        label={[{ vendorcode: 'ALL', vendorname: 'All' }, ...vendorState.list]?.find((item) => item.vendorcode === value)?.vendorname}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                    multiple={true}
                                        onChange={(e) => {
                                            if (e.target.value.includes("ALL")) {
                                                setSelectedCacheVendor(["ALL"]);
                                            } else {
                                                setSelectedCacheVendor(e.target.value);
                                            }
                                        }}
                                        label="Select Vendors"
                                        disabled={vendorState?.list?.length === 0 ? true : false}
                                    >
                                        {
                                            vendorState?.list ? (
                                                [{ vendorcode: 'ALL', vendorname: 'All' }, ...vendorState.list].map((vendor, i) => {
                                                    return (
                                                        <MenuItem key={i} value={vendor?.vendorcode}>
                                                            <Checkbox
                                                                color="primary"
                                                            checked={selectedCacheVendor?.includes(vendor?.vendorcode)}
                                                            />
                                                            <ListItemText primary={vendor?.vendorname}/>
                                                        </MenuItem>
                                                    );
                                                })
                                            ) : (
                                                <MenuItem value={0}>
                                                    <i>No Vendor</i>
                                                </MenuItem>
                                            )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                    <InputLabel>
                                        Select Cache
                                    </InputLabel>
                                    <Select
                                        value={cacheName}
                                        label="Select Cache"
                                        onChange={(e) => setCacheName(e.target.value)}
                                    >
                                        {Object.values(CACHE_NAME).map((item, i) => {
                                            return (
                                                <MenuItem key={i} value={item}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            </div>
                            <Grid item xs={12} style={{ textAlign: 'end', padding: '16px' }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    disabled={!cacheName || selectedCacheVendor.length === 0}
                                    onClick={async () => {
                                        try {
                                            const res = await deleteReportCache(selectedCacheVendor.join(","), cacheName);
                                            handleSnackBar(true);
                                            setSnackBarMessage(res?.message);
                                        } catch (e) {
                                            console.error("Delete cache failed", e);
                                        }
                                    }}
                                >
                                    Delete
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled={!cacheName || selectedCacheVendor.length === 0}
                                    onClick={async () => {
                                        try {
                                            const res = await createReportCache(selectedCacheVendor.join(","), cacheName);
                                            handleSnackBar(true);
                                            setSnackBarMessage(res?.message);
                                        } catch (e) {
                                            console.error("Create cache failed", e);
                                        }
                                    }}
                                    style={{
                                        marginLeft: "16px"
                                    }}
                                >
                                    Create
                                </Button>
                            </Grid>
                        </Widget>
                    </Grid>
                    <Grid item xs={12} >
                        <Widget
                            upperTitle
                            noBodyPadding
                            bodyClass={classes.tableOverflow}
                            header={
                                <div className={classes.mainChartHeader}>
                                    <Typography
                                        variant="h2"
                                        gutterBottom
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 20,
                                            color: "black",
                                        }}
                                    >
                                        {`Generate Historic Data`}
                                    </Typography>
                                </div>
                            }
                        >
                            {vendorState?.list ? <Form
                                selectedVendor={selectedVendor}
                                setSelectedVendor={setSelectedVendor}
                                selectedReportType={selectedReportType}
                                setSelectedReportType={setSelectedReportType}
                                vendorList={vendorState?.list ? [{ vendorcode: 'ALL', vendorname: 'All' }, ...vendorState.list] : []}
                                generateReport={generateReport}
                                segmentId={segmentId}
                                setSegmentId={setSegmentId}
                            /> : <></>}
                        </Widget>
                    </Grid>
                </Grid>
                <Backdrop
                    className={classes.backdrop}
                    open={fullPageLoader}
                // onClick={() => setfullPageLoader(false)}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} open={snackBar} autoHideDuration={6000} onClose={handleSnackBar}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        {snackBarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </>
    );
}
