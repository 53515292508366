import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import axios from "axios";
import * as XLSX from 'xlsx';

//icons
import RefreshIcon from '@material-ui/icons/Refresh';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import ConfirmDialog from "./components/ComfirmDialog/ConfirmDialog";
import Table from "./components/Table/Table";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";
import { RAPID_API_COUNTRY } from "../../constant/content";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [file, setfile] = useState({name:"No File uploaded"});
  const [confirmDialog, setconfirmDialog] = useState(false);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [estimatedTime, setestimatedTime] = useState(null);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);

  async function uploadCSV (e){
    const file = e.target.files[0];
    const reader = new FileReader();

      reader.onload = evt => {
        const wb = XLSX.read(evt.target.result, { type: 'array' });
        const ws = wb.Sheets[wb.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(ws, { defval: "" });

        if (data.length === 0) {
          seterr("No data available in the file");
          setTimeout(() => {
            seterr(null)
          }, 2000);
        } else {
        const errPromise = new Promise((resolve, reject) => {
          data.forEach((entry,index) => {
            if (!entry.ASIN || !entry.Country) {
              reject(index+1);
            } else {
              resolve("No error found");
            }
          });
        });

        errPromise
          .then(()=>{
            setfile(file);
            setconfirmDialog(true);
            convertSeconds(((data.length - 1)*5)*2); //rough time to fetch API is 5 sec.
          })
          .catch((rowNumber)=>{
            seterr(`Uploaded file is not in proper format. Issue found in row no. ${rowNumber}`);            

            document.getElementById('upload-csv').value = '';
            setTimeout(() => {
              seterr(null)
            }, 2000);
            setfile({name:"No File uploaded"});
            setconfirmDialog(false);
            setestimatedTime(null);
          })
        }
      };
    reader.readAsArrayBuffer(file);
  }

  function convertSeconds(seconds) {
    var convert = function(x) { return (x < 10) ? "0"+x : x; }
    setestimatedTime(convert(parseInt(seconds / (60*60))) + ":" +
           convert(parseInt(seconds / 60 % 60)) + ":" +
           convert(seconds % 60))
  }

  const fetchData = () => {
    axios.get( BASE_URL + api.listProductDetailsHiRes + userState.userData.email ) // need to tokenize later
      .then((res)=>{
        setmainTableData(res.data)
      })
      .catch((err)=>{
        console.log(err)
      })
  }

  useEffect(() => {
    if(userState.userData) {
      fetchData();
    }
  }, [userState.userData])

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Product HI-Res Images"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Catalog
              </Link>
              <Typography className={classes.link} color="primary">
                Product HI-Res Images
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <div>
                <Button 
                  color="primary" 
                  variant="outlined" 
                  component="span"
                  size="small"
                  startIcon={<GetAppIcon />}
                  onClick={() => {
                    const sampleData = [
                      { ASIN: "ASIN1", Country: "US" },
                      { ASIN: "ASIN2", Country: "US" },
                      { ASIN: "ASIN3", Country: "UK" }
                    ];
                    const allowedCountries = RAPID_API_COUNTRY.map((item) => {
                      return {
                        "Country Name": item.label,
                        "Country": item.value,
                      };
                    });
                    const ws1 = XLSX.utils.json_to_sheet(sampleData);
                    const ws2 = XLSX.utils.json_to_sheet(allowedCountries);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws1, "Sheet1");
                    XLSX.utils.book_append_sheet(wb, ws2, "Allowed Countries");
                    XLSX.writeFile(wb, "Sample File.xlsx");
                  }}
                  >
                  Download Sample File
                </Button>
                </div>
              <FormControl variant="outlined" className={classes.formControl} style={{marginRight:'-5%'}}>
                <label htmlFor="upload-csv">
                  <input style={{ display: 'none' }} 
                    id="upload-csv"  
                    name="upload-csv"
                    type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                    onChange={(e) => uploadCSV(e)}
                  /> 
                  <Button 
                    color="primary" 
                    variant="contained" 
                    component="span"
                    size="medium"
                    startIcon={<PublishIcon />}
                    >
                    Upload here
                  </Button>
                </label>
                <Typography className={classes.link} color="primary" style={{fontSize:12, margin:'5% 0% 0% 1%'}}>
                  {file.name}
                </Typography>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <div/>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchData()}
                  >
                    Refresh
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog open={confirmDialog} toggleDialog={()=>setconfirmDialog(!confirmDialog)} file={file} setFile={setfile} estimatedTime={estimatedTime} setnotification={setnotification} seterr={seterr}/>
      {
        notification ? 
          <Alert severity="success" style={{position:'absolute', bottom: 30, left: '48%'}}>{notification}</Alert>
        :
        <></>
      }      
      {
        err ? 
          <Alert severity="error" style={{position:'absolute', bottom: 30, left: '48%'}}>{err}</Alert>
        :
        <></>
      }    
    </>
  );
}
