import React, { useState, useRef , forwardRef, useImperativeHandle } from "react";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
import { getFaviconUrl } from "healpers/utilityFunctions";
import moment from "moment";

export default forwardRef ( function Tables(props,ref) {

  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);


  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if(gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({onlySelected: true});
      else 
      gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };

  const onSelectionChanged = () => {
    const selectedRows = gridApi.getSelectedRows();
    props.selectedRowData(selectedRows)
  };

  const renderImage = ({ value }) => `<img style="height: 40px; width: 40px" src="${getFaviconUrl(value)}">`;

  return (
    <div className="ag-theme-material ams-campaigns" style={{height: 620, width: '100%'}} id="#grid-theme-wrapper">
      <AgGridReact
        onSelectionChanged={onSelectionChanged}
        headerCheckboxSelectionCurrentPageOnly={true} 
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable:true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={50}
        rowStyle={rowStyle}
        fullWidth
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}
        suppressLoadingOverlay
      >
        <AgGridColumn headerName={''} field={'asin'} width={props.dense ? 50 : 100} cellRenderer={renderImage}/>
        <AgGridColumn headerName="ASIN" field="asin" width={props.dense ? 160 : undefined}/>
        <AgGridColumn headerName="SKU" field="sku"/>
        <AgGridColumn headerName="Image" field="image" width={props.dense ? 200 : 300} />
        <AgGridColumn headerName="Type" field="imageType" width={props.dense ? 120 : undefined} />
        <AgGridColumn headerName="Status" field="status" width={props.dense ? 160 : undefined} />
        <AgGridColumn headerName="Date" field="createdDate" cellRenderer={ ({ value }) => moment(value).format("MM/DD/YYYY HH:mm")}/>
      </AgGridReact>
    </div>
  );
})