import React, { useState, useRef, useEffect } from "react";
import {
	Checkbox,
	Grid,
	Breadcrumbs,
	Select,
	FormControl,
	MenuItem,
	Container,
	Button,
	CircularProgress,
	Backdrop,
	InputLabel,
	Link,
	TextField,
} from "@material-ui/core";
import axios from "axios";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import moment from "moment";
import XLSX from "xlsx";

//icons
import PrintIcon from "@material-ui/icons/Print";
import ImageIcon from "@material-ui/icons/InsertPhoto";
import MultipleImageIcon from "@material-ui/icons/Collections";
import WarningIcon from "@material-ui/icons/Warning";
import ZipIcon from "@material-ui/icons/CreateNewFolder";
import RefreshIcon from "@material-ui/icons/Refresh";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table/Table";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

const IMAGE_TYPES = [
	"MAIN",
	"PT01",
	"PT02",
	"PT03",
	"PT04",
	"PT05",
	"PT06",
	"PT07",
	"PT08",
	"SWCH",
];

export default function CatalogImageManager(props) {
	var classes = useStyles();
	const exportFunctionRef = useRef();
	var vendorState = useVendorState();
	const [activeTable, setActiveTable] = useState("MAIN");
	const [mainTableData, setmainTableData] = useState(null);
	const [uploadedZipTableData, setUploadedZipTableData] = useState([]);
	const [open, setOpen] = useState(false);
	const [openMultipleImageUpload, setOpenMultipleImageUpload] = useState(false);
	const [openZipUpload, setOpenZipUpload] = useState(false);
	const [selectedZipId, setSelectedZipId] = useState(null);
	const [selectedZipName, setSelectedZipName] = useState("");
	const [openZipTable, setOpenZipTable] = useState(false);
	const [zipTableData, setZipTableData] = useState([]);
	const [ASIN, setASIN] = useState("");
	const [fullPageLoader, setfullPageLoader] = useState(true);
	const [file, setFile] = useState(null);
	const [imageType, setImageType] = useState("");
	const [multipleImages, setMultipleImages] = useState([]);
	const [selectedMultipleImages, setSelectedMultipleImages] = useState([]);
	const [selectedZip, setSelectedZip] = useState(null);
	const [isVCVendorCodeAvailable, setVCVendorCodeAvailable] = useState(true);
	const [vcVendorCodeAvailabilityForASIN, setVCVendorCodeAvailabilityForASIN] = useState(true);

	async function loadData() {
		try {
			setfullPageLoader(true);
			let params = {
				vendorCode: vendorState.selected
			};
			const result = await axios.get(BASE_URL + api.catalogImages, tokenConfig(params));
			console.log("result", result);
			if (result.data.data) {
				setmainTableData(result.data.data);
			}
			setfullPageLoader(false);
		} catch (error) {
			console.log(error);
			setfullPageLoader(false);
		}
	}

	const loadUploadedZipData = async () => {
		try {
			setfullPageLoader(true);
			const params = {
				vendorCode: vendorState.selected
			};
			const result = await axios.get(BASE_URL + api.fetchCatalogImageZip, tokenConfig(params));
			if (result.data.data) {
				setUploadedZipTableData(result.data.data);
			}
			setfullPageLoader(false);
		} catch (error) {
			console.log(error);
			setfullPageLoader(false);
		}
	}

	const loadZipTableData = async () => {
		try {
			const params = {
				vendorCode: vendorState.selected
			};
			const result = await axios.get(BASE_URL + api.catalogImages + "?zipManagerId=" + selectedZipId, tokenConfig(params));
			if (result.data.data) {
				setZipTableData(result.data.data);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async function checkVcVendorCode() {
		try {
			const params = {
				vendorCode: vendorState.selected
			}
			const result = await axios.get(BASE_URL + api.checkVcVendorCode, tokenConfig(params));
			setVCVendorCodeAvailable(result.data.data);
		} catch (error) {
			console.log("Failed to check vc vendor code", error);
		}
	}

	async function checkVCVendorCodeWithASIN() {
		if (ASIN) {
			try {
				const params = {
					asin: ASIN
				}
				const result = await axios.get(BASE_URL + api.checkVcVendorCodeWithASIN, tokenConfig(params));
				setVCVendorCodeAvailabilityForASIN(result.data.data);
			} catch (error) {
				console.log("Failed to check vc vendor code", error);
			}
		} else {
			setVCVendorCodeAvailabilityForASIN(true);
		}
	}

	useEffect(() => {
		if (vendorState.selected !== 0) {
			loadData();
			if (vendorState.selected.split("_")?.pop() === "VC") {
				checkVcVendorCode();
			} else {
				setVCVendorCodeAvailable(true);
			}
		}
		return () => {
			setmainTableData(null);
		};
	}, [vendorState.selected]);

	useEffect(() => {
		if (activeTable === "MAIN") {
			loadData();
		} else if (activeTable === "UPLOADS") {
			loadUploadedZipData();
		}
	}, [activeTable]);

	useEffect(() => {
		if (selectedZipId) {
			loadZipTableData();
		}
	}, [selectedZipId]);

	const handleSelectImageType = (event) => {
		setImageType(event.target.value);
	};

	const getImageType = (imageName = "") => {
		return imageName.split(".")?.[1] || "INVALID";
	};

	const onCloseModal = () => {
		setOpen(false);
		setASIN("");
		setFile(null);
		setImageType("");
	};

	const onCloseMultipleImageModal = () => {
		setOpenMultipleImageUpload(false);
		setASIN("");
		setSelectedMultipleImages([]);
		setMultipleImages([]);
	};

	const onCloseZipUploadModal = () => {
		setOpenZipUpload(false);
		setSelectedZip(null);
	};

	const onCloseZipTableDataModal = () => {
		setOpenZipTable(false);
		setSelectedZipId(null);
		setSelectedZipName("");
		setZipTableData([]);
	};

	const uploadImage = (event) => {
		const file = event.target.files[0];
		event.target.value = null;
		setFile(file);
		console.log("file", file);
	}

	const onMultipleImageUpload = (event) => {
		const files = [...event.target.files].filter((item) => item.type.startsWith("image/"));
		setMultipleImages(files);
		setSelectedMultipleImages(files.filter((item) => IMAGE_TYPES.includes(getImageType(item.name.toUpperCase()))))
	}

	const onZipUpload = (event) => {
		setSelectedZip(event.target.files?.[0]);
	}

	const handleSubmit = async () => {
		try {
			setfullPageLoader(true);
			const formData = new FormData();
			formData.append("file", file);
			formData.append("asin", ASIN);
			formData.append("imageType", imageType);
			formData.append("imageName", file.name);
			const { data: { data } } = await axios.post(BASE_URL + api.uploadCatalogImage, formData, tokenConfig());
			const errorData = data.filter(item => item.isError);
			if (errorData.length > 0) {
				const errors = [];
				errorData.forEach(item => {
					errors.push({
						ASIN: item.asin || "",
						SKU: item.sku || "",
						"Vendor Code": item.vendorCode || "",
						Image: file.name,
						"Image Type": item.imageType || "",
						Status: "FAILED",
						Message: item.message || "",
					});
				});
				alert("Found some errors during image processing, check the error file for more details");
				const ws = XLSX.utils.json_to_sheet(errors);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws);
				XLSX.writeFile(wb, `Catalog Image Manager Errors ${moment().format("MM/DD/YYYY")}.xlsx`);
			} else {
				alert("Images uploaded successfully");
			}
			await loadData();
			setfullPageLoader(false);
			onCloseModal()
		} catch (error) {
			alert("Failed to upload images");
			console.log("Failed to upload", error);
			setfullPageLoader(false);
			onCloseModal()
		}
	}

	const handleMultipleImageUpload = async () => {
		try {
			setfullPageLoader(true);
			const errors = [];
			for (const file of selectedMultipleImages) {
				const formData = new FormData();
				formData.append("file", file);
				formData.append("asin", ASIN);
				formData.append("imageType", getImageType(file.name));
				formData.append("imageName", file.name);
				const { data: { data } } = await axios.post(BASE_URL + api.uploadCatalogImage, formData, tokenConfig());
				const errorData = data.filter(item => item.isError);
				if (errorData.length > 0) {
					errorData.forEach(item => {
						errors.push({
							ASIN: item.asin || "",
							SKU: item.sku || "",
							"Vendor Code": item.vendorCode || "",
							Image: file.name,
							"Image Type": item.imageType || "",
							Status: "FAILED",
							Message: item.message || "",
						});
					});
				}
			}
			if (errors.length > 0) {
				alert("Found some errors during image processing, check the error file for more details");
				const ws = XLSX.utils.json_to_sheet(errors);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws);
				XLSX.writeFile(wb, `Catalog Image Manager Errors ${moment().format("MM/DD/YYYY")}.xlsx`);
			} else {
				alert("Images uploaded successfully");
			}
			await loadData();
			setfullPageLoader(false);
			onCloseMultipleImageModal();
		} catch (error) {
			alert("Failed to upload multiple images");
			console.log("Failed to upload multiple images", error);
			setfullPageLoader(false);
			onCloseMultipleImageModal();
		}
	}

	const handleSubmitZip = async () => {
		try {
			setfullPageLoader(true);
			const formData = new FormData();
			formData.append("file", selectedZip);
			const { data } = await axios.post(BASE_URL + api.uploadCatalogImageZip, formData, tokenConfig());
			alert(data.message);
			await loadUploadedZipData();
			setfullPageLoader(false);
			onCloseZipUploadModal();
		} catch (error) {
			alert("Failed to upload zip");
			console.log("Failed to upload zip", error);
			setfullPageLoader(false);
			onCloseZipUploadModal();
		}
	}

	const ActionButtonsRenderer = (props) => {
		return (
			<div
				style={{
					display: "flex",
					gap: "8px",
					paddingTop: "8px",
				}}
			>
				<Button
					variant="contained"
					color="primary"
					size="small"
					disabled={["PENDING", "IN_PROGRESS"].includes(props.data.status)}
					onClick={async () => {
						try {
							const retry = await axios({
								method: "post",
								url: BASE_URL + api.retryCatalogImageZip,
								headers: {
									...tokenConfig().headers,
								},
								data: {
									id: props.data.id,
								},
							});
							if (retry.data?.status === "success") {
								window.alert(retry.data?.message);
								await loadUploadedZipData();
							} else {
								window.alert(
									retry.data?.message || "Failed to initiate retry."
								);
							}
						} catch (e) {
							console.log(e);
							window.alert("Failed to initiate retry.");
						}
					}}
				>
					Retry
				</Button>
				<Button
					variant="contained"
					color="primary"
					size="small"
					disabled={props.data.status !== "COMPLETED"}
					onClick={() => {
						const zipName = props.data.zip.replace("product_images/", "");
						const downloadURL = `${BASE_URL}${api.downloadCatalogImageZipOutput}/Result-${zipName}`;
						window.open(downloadURL.substring(0, downloadURL.lastIndexOf(".")) + ".xlsx", "_blank", "noopener,noreferrer");
					}}
				>
					Download
				</Button>
			</div>
		);
	};

	const ZipNameRenderer = (props) => {
		return (
			<div
				style={{
					display: "flex",
					gap: "8px",
					paddingTop: "8px",
				}}
			>
				<Button
					variant="text"
					color="primary"
					size="small"
					disabled={props.data.status === "PENDING"}
					onClick={() => {
						const zipName = props.data.zip.replace("product_images/", "");
						setSelectedZipId(props.data.id);
						setSelectedZipName(zipName);
						setOpenZipTable(true);
					}}
					style={{
						textTransform: "none",
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					{props.data.zip.replace("product_images/", "")}
				</Button>
			</div>
		);
	};

	return (
		<Container maxWidth={false}>
			<PageTitle
				title="Catalog Image Manager"
				breadCrump={
					<Breadcrumbs aria-label="breadcrumb">
						<Link color="inherit" className={classes.link}>
							Catalog
						</Link>
						<Typography className={classes.link} color="primary">
							Catalog Image Manager
						</Typography>
					</Breadcrumbs>
				}
			/>
			<Grid container spacing={4} className="ams_campaign_grid">
				<Grid item xs={12} className="ams_campaign_grid_item">
					<Widget
						upperTitle
						noBodyPadding
						bodyClass={classes.tableOverflow}
						header={
							<div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "16px" }}>
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
									<div>
										<Typography variant="p" style={{ color: "red" }}>{isVCVendorCodeAvailable ? "" : "VC Vendor Code is not available. Please add it before uploading images."}</Typography>
									</div>
									<div>
										<Button
											variant="contained"
											color="primary"
											size="small"
											style={{ marginRight: 20 }}
											startIcon={<ZipIcon />}
											onClick={() => setOpenZipUpload(true)}
										>
											Upload ZIP
										</Button>
										<Button
											variant="contained"
											color="primary"
											size="small"
											style={{ marginRight: 20 }}
											startIcon={<MultipleImageIcon />}
											onClick={() => setOpenMultipleImageUpload(true)}
										>
											Upload Multiple Image
										</Button>
										<Button
											variant="contained"
											color="primary"
											size="small"
											startIcon={<ImageIcon />}
											onClick={() => setOpen(true)}
										>
											Upload Single Image
										</Button>
									</div>
								</div>
								<div style={{
									display: "flex",
									justifyContent: "space-between",
									width: "100%"
								}}>
									<Button
										variant="contained"
										color="primary"
										size="small"
										startIcon={<PrintIcon />}
										disabled={activeTable !== "MAIN"}
										onClick={() => exportFunctionRef.current.onBtnExport()}
									>
										Export
									</Button>
									<div>
										<Button
											variant="text"
											color="primary"
											size="small"
											style={{ marginRight: 20 }}
											onClick={() => setActiveTable(
												activeTable === "MAIN" ? "UPLOADS" : "MAIN"
											)}
										>
											{`View ${activeTable === "MAIN" ? "Upload Zips" : "Images"}`}
										</Button>
										<Button
											variant="contained"
											color="primary"
											size="small"
											startIcon={<RefreshIcon />}
											onClick={async () => 
												activeTable === "MAIN" ? await loadData() : await loadUploadedZipData() 
											}
										>
											Refresh
										</Button>
									</div>
								</div>
							</div>
						}
					>
						{activeTable === "MAIN"
							?
								<Table
									ref={exportFunctionRef}
									tableData={mainTableData}
									navigate={props.history.push}
								/>
							:
								<div
									className="ag-theme-material"
									style={{ height: 550, width: "100%" }}
									id="#grid-theme-wrapper"
								>
									<AgGridReact
										rowData={uploadedZipTableData}
										suppressExcelExport={true}
										defaultColDef={{
											filter: "agTextColumnFilter",
											resizable: true,
											sortable: true,
											headerComponentParams: {
												template:
												'<div class="ag-cell-label-container" role="presentation">' +
												'  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
												'  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
												'    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
												'    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
												'    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
												'    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
												'    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
												'    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
												"  </div>" +
												"</div>",
											},
										}}
										frameworkComponents={{
											actionButtonsRenderer: ActionButtonsRenderer,
											zipNameRenderer: ZipNameRenderer
										}}
										applyColumnDefOrder={true}
										pagination={true}
										paginationPageSize={10}
										suppressDragLeaveHidesColumns={true}
									>
										<AgGridColumn
											headerName="File"
											cellRenderer="zipNameRenderer"
											width={450}
										/>
										<AgGridColumn
											headerName="Status"
											field="status"
											width={140}
										/>
										<AgGridColumn
											headerName="Uploaded On"
											field="createdDate"
											cellRenderer={({ value }) =>
												moment(value).format("MM-DD-YYYY")
											}
											width={160}
										/>
										<AgGridColumn
											headerName="User"
											field="createdBy"
											width={250}
										/>
										<AgGridColumn
											cellRenderer="actionButtonsRenderer"
											filter={false}
											sortable={false}
											width={220}
										/>
									</AgGridReact>
								</div>
						}
					</Widget>
				</Grid>
			</Grid>
			<Backdrop className={classes.backdrop} open={fullPageLoader} onClick={() => setfullPageLoader(false)}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Modal open={open} onClose={() => onCloseModal()} styles={{ modal: { width: 600, borderRadius: 10 } }} center>
				<Backdrop className={classes.backdrop} open={fullPageLoader} onClick={() => setfullPageLoader(false)}></Backdrop>
				<Grid container style={{ paddingBottom: 30 }} alignItems="center" flexDirection="row">
					<Typography style={{ fontSize: 16, paddingRight: 10, fontWeight: 600 }}>Upload</Typography>
				</Grid>
				<Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
					<FormControl fullWidth sx={{ m: 1 }}>
						<TextField
							type="text"
							onBlur={() => checkVCVendorCodeWithASIN()}
							value={ASIN}
							id="outlined-adornment-amount"
							name="asin"
							onChange={(e) => {
								setASIN(e.target.value);
							}}
							label="ASIN"
							variant="outlined"
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="advertising-goal-label">Image Type</InputLabel>
						<Select
							labelId="advertising-goal-label"
							value={imageType}
							onChange={handleSelectImageType}
							name="imageType"
							label="Image Type"
						>
							<MenuItem value={"MAIN"}>Main</MenuItem>
							<MenuItem value={"PT01"}>PT1</MenuItem>
							<MenuItem value={"PT02"}>PT2</MenuItem>
							<MenuItem value={"PT03"}>PT3</MenuItem>
							<MenuItem value={"PT04"}>PT4</MenuItem>
							<MenuItem value={"PT05"}>PT5</MenuItem>
							<MenuItem value={"PT06"}>PT6</MenuItem>
							<MenuItem value={"PT07"}>PT7</MenuItem>
							<MenuItem value={"PT08"}>PT8</MenuItem>
							<MenuItem value={"SWCH"}>Swatch</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} spacing={4} style={{ paddingBottom: 10 }}>
					<FormControl fullWidth sx={{ m: 1 }} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
						<label htmlFor="upload-video">
							<input
								style={{ display: "none" }}
								id="upload-video"
								name="image"
								type="file"
								accept="image/*"
								onChange={(event) => uploadImage(event)}
							/>
							<Button
								size="medium"
								color="primary"
								variant="contained"
								component="span"
								style={{
									marginBottom: 20,
									padding: "8px 30px",
								}}
							>
								Upload Single Image
							</Button>
						</label>
						{file && <img src={file ? URL.createObjectURL(file) : ""} alt="uploaded" style={{ width: 50, height: 50 }} />}
					</FormControl>
				</Grid>
				{!vcVendorCodeAvailabilityForASIN && <Grid item xs={12} spacing={4} style={{ paddingBottom: 10 }}>
					<Typography variant="p" style={{ color: "red", fontSize: 12 }}>VC Vendor Code is not available for this ASIN. Please add it before uploading the image.</Typography>
				</Grid>}
				<Grid container fullWidth direction="row" justifyContent="center" alignItems="center">
					<Button variant="contained" color="primary" size="small" onClick={() => handleSubmit()}>
						Submit
					</Button>
				</Grid>
			</Modal>
			<Modal open={openMultipleImageUpload} onClose={() => onCloseMultipleImageModal()} styles={{ modal: { width: 600, borderRadius: 10 } }} center>
				<Grid container style={{ paddingBottom: 16 }} alignItems="center" flexDirection="row">
					<Typography style={{ fontSize: 16, paddingRight: 10, fontWeight: 600 }}>Multiple Image Upload</Typography>
				</Grid>
				<Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
					<FormControl fullWidth sx={{ m: 1 }}>
						<TextField
							type="text"
							onBlur={() => {
								if (ASIN.length === 10) checkVCVendorCodeWithASIN();
							}}
							value={ASIN}
							name="asin"
							onChange={(e) => {
								setASIN(e.target.value);
							}}
							label="ASIN"
							variant="outlined"
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} spacing={4} style={{ paddingBottom: 16, maxHeight: 360, overflowY: "scroll" }}>
					{multipleImages.map((item, index) => 
						<div key={index} style={{
							display: "flex",
							gap: "8px",
							alignItems: "center",
							padding: "8px",
						}}>
							<Checkbox 
                                color="primary"
								checked={selectedMultipleImages.map((image) => image.name).includes(item.name)}
								disabled={!IMAGE_TYPES.includes(getImageType(item.name.toUpperCase()))}
								onChange={(event) => {
									if (event.target.checked) {
										setSelectedMultipleImages([...selectedMultipleImages, item]);
									} else {
										setSelectedMultipleImages([...selectedMultipleImages.filter((image) => image.name !== item.name)])
									}
							}}/>
							<img src={URL.createObjectURL(item)} width={50} height={50}/>
							<Typography>{item.name?.replace("\"", "")}</Typography>
							{!IMAGE_TYPES.includes(getImageType(item.name.toUpperCase())) && <WarningIcon color="error" fontSize="small" titleAccess="Invalid image type, rename the image and try again." />}
						</div>
					)}
				</Grid>
				<Grid item xs={12} spacing={4} style={{ paddingBottom: 10, paddingTop: 10 }}>
					<FormControl fullWidth sx={{ m: 1 }} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
						<label htmlFor="upload-multiple-image">
							<input
								style={{ display: "none" }}
								id="upload-multiple-image"
								name="multiple-image"
								type="file"
								directory=""
								webkitdirectory=""
								multiple={true}
								onChange={(event) => onMultipleImageUpload(event)}
							/>
							<Button
								size="medium"
								color="primary"
								variant="contained"
								component="span"
								style={{
									marginBottom: 20,
									padding: "8px 30px",
								}}
							>
								Upload Multiple Image
							</Button>
						</label>
					</FormControl>
				</Grid>
				{!vcVendorCodeAvailabilityForASIN && <Grid item xs={12} spacing={4} style={{ paddingBottom: 10 }}>
					<Typography variant="p" style={{ color: "red", fontSize: 12 }}>VC Vendor Code is not available for this ASIN. Please add it before uploading the image.</Typography>
				</Grid>}
				<Grid container fullWidth direction="row" justifyContent="center" alignItems="center">
					<Button variant="contained" color="primary" size="small" onClick={() => handleMultipleImageUpload()} disabled={!ASIN || selectedMultipleImages.length === 0} >
						Submit
					</Button>
				</Grid>
			</Modal>
			<Modal open={openZipUpload} onClose={() => onCloseZipUploadModal()} styles={{ modal: { width: 600, borderRadius: 10 } }} center>
				<Grid container style={{ paddingBottom: 16 }} alignItems="center" flexDirection="row">
					<Typography style={{ fontSize: 16, paddingRight: 10, fontWeight: 600 }}>Upload Zip</Typography>
				</Grid>
				{selectedZip &&<Grid item xs={12} spacing={4}>
					<Typography>{selectedZip?.name?.replace("\"", "")}</Typography>
				</Grid>}
				<Grid item xs={12} spacing={4} style={{ paddingBottom: 10, paddingTop: 10 }}>
					<FormControl fullWidth sx={{ m: 1 }} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
						<label htmlFor="upload-zip">
							<input
								style={{ display: "none" }}
								id="upload-zip"
								name="image-zip"
								type="file"
								accept=".zip"
								onChange={(event) => onZipUpload(event)}
							/>
							<Button
								size="medium"
								color="primary"
								variant="contained"
								component="span"
								style={{
									padding: "8px 30px",
								}}
							>
								Upload Zip
							</Button>
						</label>
					</FormControl>
				</Grid>
				<Grid container fullWidth direction="row" justifyContent="center" alignItems="center">
					<Button variant="contained" color="primary" size="small" onClick={() => handleSubmitZip()} disabled={!selectedZip}>
						Submit
					</Button>
				</Grid>
			</Modal>
			<Modal open={openZipTable} onClose={() => onCloseZipTableDataModal()} styles={{ modal: { width: 950, borderRadius: 10, maxWidth: 950 } }} center>
				<Typography>{selectedZipName}</Typography>
				<Table
					ref={exportFunctionRef}
					tableData={zipTableData}
					dense={true}
				/>
			</Modal>
		</Container>
	);
}
