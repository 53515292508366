import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Tab,
  Tabs,
  Button,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import ErrorIcon from "@material-ui/icons/Error";

// react-jsonschema-form
import Form from "@rjsf/material-ui";
import validator from "@rjsf/validator-ajv8";
import * as jsonRefs from "json-refs";
import { addedDiff, updatedDiff } from "deep-object-diff";
import { useHistory } from "react-router-dom";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import { useVendorState } from "../../context/VendorContext";

// component
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { tokenConfig } from "../../context/UserContext";
import _ from "lodash";

const CustomErrorsMessages = (props) => {
  return (
    <Box
      style={{
        color: "#4A4A4A",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "#fff",
        padding: "16px",
        marginBottom: "16px",
      }}
    >
      <Typography variant="h6">Errors</Typography>
      <List>
        {props.errors.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <ErrorIcon style={{ color: "red" }} />
            </ListItemIcon>
            <ListItemText>
              <Typography style={{ color: "red" }} variant="body2">
                {item}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default function UpdateItem(props) {
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [editFormData, setEditFormData] = useState({});
  const [patchData, setPatchData] = useState([]);
  const [formSchema, setFormSchema] = useState({});
  const [errorMessage, setErrorMessage] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [notification, setNotification] = useState(null);
  const vendorState = useVendorState();
  const history = useHistory();
  const [productOptions, setProductOptions] = useState({});

  async function loadProductData(productOptions) {
    try {
      setFullPageLoader(true);
      const response = await axios.get(
        BASE_URL + api.spListing + productOptions.vendorCode,
        tokenConfig({ ...productOptions, spApi: "true" })
      );

      setFormData(response.data.data.attributes);
      setEditFormData(_.cloneDeep(response.data.data.attributes));
      const productSchema = response.data.data.productSchema;
      const resolveProductSchema = await jsonRefs.resolveRefs(productSchema);
      setFormSchema(resolveProductSchema.resolved);
      setPatchData([]);

      setFullPageLoader(false);
    } catch (err) {
      console.error(err);
      const { response } = err;
      if (response.data && response.data.message) {
        const { message, errors } = response.data;
        const messages = errors ? [message, ...errors] : [message];
        setErrorMessage(messages);
      } else {
        setErrorMessage(["Error occurred while loading data"]);
      }
      setFullPageLoader(false);
    }
  }

  const onSubmit = async () => {
      setFullPageLoader(true);
      const payload = {
        productType: productOptions.productType, 
        patches: patchData,
        updatedAttributes: editFormData
      };
      try {
        const response = await axios.post(
          BASE_URL + api.spPatchListing + productOptions.vendorCode,
          payload,
          tokenConfig(productOptions)
        );

        setNotification(response.data.message);
        setTimeout(() => {
          setNotification(null);
        }, 2000);
        setFormData(_.cloneDeep(editFormData));
        setPatchData([]);
      } catch (err) {
        const { response } = err;
        if (response.data && response.data.message) {
          const { message, errors } = response.data;
          const messages = errors ? [message, ...errors] : [message];
          setErrorMessage(messages);
        }
      } finally {
        setFullPageLoader(false);
      }
  };

  useEffect(() => {
    if (props?.location?.state && vendorState?.selected) {
      const { sku, asin, productType, marketplaceId, vcVendorCode } = props.location.state;
      if (sku && asin && marketplaceId) {
        setProductOptions({
          vendorCode: vendorState.selected,
          vcVendorCode: vendorState.selected?.endsWith("_VC") ? vcVendorCode : undefined,
          asin,
          sku,
          productType,
          marketplaceId,
        });
      }
    }

    return () => {
      setProductOptions({});
    };
  }, [props.location.state, vendorState.selected]);

  // Load the data from the API
  useEffect(() => {
    const { asin, sku, marketplaceId, vendorCode } = productOptions;
    if (asin && sku && marketplaceId && vendorCode) {
      setErrorMessage([]);
      console.log("Loading data", productOptions);
      loadProductData(productOptions);
    } else {
      setErrorMessage([
        "Either one or more of asin, sku or marketplaceId is missing",
      ]);
    }
  }, [productOptions]);

  useEffect(() => {
    const patches = [];

    const updateDiff = updatedDiff(formData, _.cloneDeep(editFormData));
    for (const item of Object.keys(updateDiff)) {
      const isEmpty = ["[]", "[{}]"].includes(JSON.stringify(editFormData[item]));
      if (isEmpty) {
        if (productOptions.vendorCode.endsWith("_SC")) {
          // Delete operation supported only for SC
          patches.push({
            op: "delete",
            path: `/attributes/${item}`,
            value: formData[item],
          });
        }
      } else {
        patches.push({
          op: "replace",
          path: `/attributes/${item}`,
          value: editFormData[item],
        });
      }
    }

    const addDiff = addedDiff(formData, _.cloneDeep(editFormData));
    for (const item of Object.keys(addDiff)) {
      // Skip empty data and skip if already added in updateDiff.
      if (!["[]", "[{}]"].includes(JSON.stringify(editFormData[item])) && !Object.keys(updateDiff).includes(item)) {
        patches.push({
          op: "replace",
          path: `/attributes/${item}`,
          value: editFormData[item],
        });
      }
    }
    setPatchData(_.cloneDeep(patches));
  }, [editFormData]);

  const [tabValue, setTabValue] = useState(0);
  const [showJSON, setShowJSON] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Update item"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="primary"
                className={classes.link}
                onClick={() => history.push("/app/catalog/catalogItems")}
              >
                Catalog
              </Link>
              <Typography className={classes.link} color="inherit">
                Update Item
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div style={{
              backgroundColor: "#FFFFFF",
              padding: "8px 16px",
              borderRadius: "4px",
            }}>
              <Typography>ASIN: {productOptions.asin}</Typography>
              <Typography>SKU: {productOptions.sku}</Typography>
              <Typography>Marketplace: {productOptions.marketplaceId}</Typography>
              <Typography>Vendor Code: {productOptions.vendorCode}</Typography>
              {productOptions.vcVendorCode &&<Typography>VC Vendor Code: {productOptions.vcVendorCode}</Typography>}
              <Typography>Product Type: {productOptions.productType}</Typography>
            </div>
            {formSchema && formData &&<Form
              schema={formSchema}
              uiSchema={{
                "ui:submitButtonOptions": { norender: true },
              }}
              validator={validator}
              formData={editFormData}
              onChange={(event) => {
                setEditFormData(_.cloneDeep(event.formData));
              }}
              liveValidate={false}
              noHtml5Validate={true}
              experimental_defaultFormStateBehavior={{
                emptyObjectFields: "skipDefaults",
              }}
            />}
          </Grid>

          <Grid item>
          <div
            style={{
              display: "flex",
              gap: "32px",
            }
          }>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setShowJSON(!showJSON)}
            >
              {showJSON ? "Hide JSON" : "Show JSON"}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setEditFormData(_.cloneDeep(formData))}
            >
              Discard Changes & Reload Form
            </Button>
            </div>
            {showJSON && (
              <>
                <AppBar position="static" style={{
                  margin: "16px 0px"
                }}>
                  <Tabs value={tabValue} onChange={handleChange} TabIndicatorProps={{
                    style: {
                      backgroundColor: "#FFFFFF",
                    }
                  }}>
                    <Tab label="Schema" />
                    <Tab label="Original Payload" />
                    <Tab label="Changed Payload" />
                    <Tab label="Patch Payload" />
                  </Tabs>
                </AppBar>
                <div style={{
                  backgroundColor: "#FFFFFF",
                  width: "650px",
                  height: "500px",
                  overflowY: "scroll",
                  border: "1px solid #A8A8A8",
                }}
                >
                {tabValue == 0 && (
                  <pre value={tabValue} index={0}>
                    {JSON.stringify(formSchema, null, 2)}
                  </pre>
                )}
                {tabValue == 1 && (
                  <pre value={tabValue} index={1}>
                    {JSON.stringify(formData, null, 2)}
                  </pre>
                )}
                {tabValue == 2 && (
                  <pre value={tabValue} index={2}>
                    {JSON.stringify(editFormData, null, 2)}
                  </pre>
                )}
                {tabValue == 3 && (
                  <pre value={tabValue} index={3}>
                    {JSON.stringify(patchData, null, 2)}
                  </pre>
                )}
                </div>
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            {errorMessage.length > 0 && (
              <CustomErrorsMessages errors={errorMessage} />
            )}
              <div style={{
                display: "flex",
                gap: "32px",
              }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onSubmit()}
                  disabled={patchData.length === 0}
                >
                  Submit
                </Button>
                <Button
                  color="default"
                  variant="contained"
                  onClick={() => history.push("/app/catalog/catalogItems")}
                >
                  Cancel
                </Button>
              </div>
          </Grid>
          {notification ? (
            <Alert
              severity="success"
              style={{ position: "relative", bottom: "3.7rem", left: "12rem" }}
            >
              {notification}
            </Alert>
          ) : (
            <></>
          )}
        </Grid>
        <Backdrop className={classes.backdrop} open={fullPageLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
