import React, { useState, useEffect } from "react";
import {
  Dialog,
  Grid,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  FormControlLabel,
  TextField,
  Divider,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

//helpers
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";

//context
import { tokenConfig, useUserState } from "../../../../context/UserContext";

//styles
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 600,
    fontSize: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 230,
    maxWidth: 600
  }
}));

export default function PageModal({
  showmodal,
  closeModal,
  selectedUser,
  toggleError,
  toggleNotification,
}) {
  const classes = useStyles();
  const userState = useUserState();
  const [email, setemail] = useState(null);
  const [firstName, setfirstName] = useState(null);
  const [lastName, setlastName] = useState(null);
  const [orgName, setorgName] = useState(null);
  const [orgPos, setorgPos] = useState(null);
  const [mobileNumber, setmobileNumber] = useState(null);
  const [customerConfigs, setCustomerConfigs] = useState([]);
  const [selectedVendorCodes, setSelectedVendorCodes] = useState([]);

  async function listCustomerConfigs() {
    try {
      const response = await axios.get(BASE_URL + api.listCustomerConfigs, tokenConfig());
      setCustomerConfigs(response.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function listUserVendorConfigs() {
    try {
      const response = await axios.get(BASE_URL + api.listUserVendorConfigs, tokenConfig({ email: selectedUser.email }));
      setSelectedVendorCodes(response.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    listCustomerConfigs();
  }, []);

  const [salesPermi, setsalesPermi] = useState({
    categories: {
      shippedCOGS: false,
      goalsAndPeformance: false,
      geographicInsights: false,
      ratingsAndReviews: false,
      allRatingsAndReview: false,
      keyMetricsVC: false,
      keyMetrics: false
    },
    allowed: false,
  });

  const [biPermi, setbiPermi] = useState({
    categories: {
      jsSegment: false,
      competitionBenchmarking: false,
      productsAndBrands: false,
      segments: false,
      competitionDetailedSales: false,
      shareOfVoice: false,
      bcg: {
        bcgOverview: false,
        bcgMatrix: false,
      },
      forecast: {
        projections: false,
        healthCheck: false,
        segmentProjections: false,
        segmentForecastHealth: false,
      }
    },
    allowed: false,
  });

  const [creativePermi, setcreativePermi] = useState({
    categories: {
      detailedPage: false,
      aPlusPages: false,
      seo: false,
      glanceViews: false,
      conversions: false,
      productImages: false,
    },
    allowed: false,
  });

  const [advertisingPermi, setadvertisingPermi] = useState({
    categories: {
      reachTrend: false,
      dayPartingDay: false,
      dayPartingHour: false,
      dayPartingAsinsDay: false,
      dayPartingAsins: false,
      priceWar: false,
      productsNotServing: false,
      CampaignsOutOfBudget: false,
    },
    allowed: false,
  });

  const [opsPermi, setopsPermi] = useState({
    categories: {
      suppressedAsins: false,
      eventNotifications: false,
      sinventoryManagement: false,
      fbaInventoryForecast: false,
      threePReport: false,
      mapViolators: false,
      strandedInventory: false,
      accountHealth: false,
      fbaReimbursements: false
    },
    allowed: false,
  });

  const [invoiceReconPermi, setinvoiceReconPermi] = useState({
    categories: {
      sellerCentralInvoices: false,
      vendorCentralInvoices: false,
    },
    allowed: false,
  });

  const [scAndCbPermi, setscAndCbPermi] = useState({
    categories: {
      sellerCentral: false,
      vendorCentral: false,
    },
    allowed: false,
  });

  const [amazonAdvertisingPermi, setAmazonAdvertisingPermi] = useState({
    categories: {
      settingsAndOptimisation: {
        campaignsOptimisation: false,
        createCampaign: false,
        salesAnalysis: false,
        topPerformingKeywords: false,
        campaignsSettings: false,
        dayPartingRules: false,
        budgetChanges: false
      },
      reports: {
        campaignPerformance: false,
        asinPerformance: false,
        keywordPerformance: false,
        searchTermPerformance: false,
        targetPerformance: false,
        budgetChanges: false,
        placementAnalysis: false,
        reachTrend: false
      },
      amsPPCAudit:false,
      amsForecast: false
    },
    allowed: false,
  });

  const [uncategorizedReports, setuncategorizedReports] = useState({
    categories: {
      brandSegmentsByDay: false,
      keywordsByAsin: false,
      salesAndRank: false,
      amsScreenOne: false,
      amsScreenTwo: false,
    },
    allowed: false,
  });

  const [productDetails, setproductDetails] = useState({
    categories: {
      productDetails: false,
      productHiResImages: false,
      asingSkuMapping: false,
      uploadHistoricData: false,
      cohesity: false,
      brandFinder: false,
      blockDomains: false,
      contactFinder: false,
      listBrands: false,
      listContacts: false,
      listEmptyDomains: false,
      searchContacts: false,
      searchOrganisations: false,
      enrichContacts: false,
      updateItem: false,
      brandFinderGlobal: false,
      catalogItems: false,
      catalogActivity: false,
      cohesityLogs: false,
      detailPage: false,
      seoUpdateCheck: false,
      catalogImageManager: false,
      catalogVariations: false,
    },
    allowed: false,
  });

  const [prospectsPermi, setprospectsPermi] = useState({
    categories: {
      categoryScrapping: false,
      brandFinder: false,
      blockDomains: false,
      contactFinder: false,
      listBrands: false,
      listContacts: false,
      listEmptyDomains: false,
      searchContacts: false,
      searchOrganisations: false,
      enrichContacts: false,
      brandFinderGlobal: false,
      domainsLLMValidation: false,
    },
    allowed: false,
  });

  const [fbaShipmentPermi, setFbaShipmentPermi] = useState({
    categories: {
      manageShipments: false,
      createNewShipment: false,
      estimateShippingCost: false,
      printLabelsOrBol: false,
      sendTransportDetails: false,
      shipmentAddress: false,
      casePackDetails: false,
      shipmentContact: false,
      sendTrackingNumbers: false
    },
    allowed: false
  });

  const [contentPermi, setContentPermi] = useState({
    categories: {
      approveContent: false,
      updateContent: false,
      changelog: false,
    },
    allowed: false,
  });

  const [usersPermi, setusersPermi] = useState({
    categories: {
      usersList: false,
      usersAdd: false,
      usersEdit: false,
    },
    allowed: false,
  });

  const [onboardingPermi, setOnboardingPermi] = useState({
    categories: {
      cronsManagement: false,
      onboarding: false,
      jsSegment: false,
      amsOnboarding: false,
      dapiStatistics: false,
      onboardingASINs: false,
      dbArchives: false,
      customerSettings: false,
      reportsHealth: false,
      deactivateAccount: false,
      mergeAccount: false,
      userGroups: false,
      uploadHistoricData: false,
      customerAccounts: false,
    },
    allowed: false,
  });

  const [amsPermi, setAMSPermi] = useState({
    categories: {
      createCampaign: false,
      campaigns: false,
      vendorSettings: false,
      amsDayParting: false,
      salesAnalysis: false,
      topPerformingKeywords: false,
      reachTrend: false,
      budgetChanges: false,
      amsPPCAudit: false
    },
    allowed: false,
  });

  const [amsReportsPermi, setAmsReportsPermi] = useState({
    categories: {
      campaignPerformance: false,
      asinPerformance: false,
      keywordPerformance: false,
      searchTermPerformance: false,
      targetPerformance: false,
      budgetChanges: false,
      placementAnalysis: false,
      reachTrend: false,
    },
    allowed: false,
  });

  const [wbrPermi, setWbrPermi] = useState({
    categories: {
      actionItems: false,
      keyOutputMetrics: false,
      keyInputMetrics: false,
      ams: false,
      otherMetrics: {
        otherKeyMetrics: false,
        progressAgainstGoals: false
      },
      amRules: false,
      weeklyInsights: false,
      funnel: false,
      financeMetrics: false,
      operationMetrics: false,
    },
    allowed: false
  })

  useEffect(() => {
    if (selectedUser) {
      listUserVendorConfigs();
      setemail(selectedUser.email);
      setfirstName(selectedUser.firstName);
      setlastName(selectedUser.lastName);
      setorgName(selectedUser.orgName);
      setorgPos(selectedUser.orgPosition);
      setmobileNumber(selectedUser.mobNumber);

      setsalesPermi(selectedUser.permissions_module_level.sales);
      setbiPermi(selectedUser.permissions_module_level.businessIntelligence);
      setcreativePermi(selectedUser.permissions_module_level.creative);
      setadvertisingPermi(selectedUser.permissions_module_level.advertising);
      setopsPermi(selectedUser.permissions_module_level.operations);
      setinvoiceReconPermi(selectedUser.permissions_module_level.invoiceReconciliations);
      setscAndCbPermi(
        selectedUser.permissions_module_level.shortageclaimsAndChargebacks
      );
      setAmazonAdvertisingPermi(selectedUser.permissions_module_level.amazonAdvertising);
      setuncategorizedReports(
        selectedUser.permissions_module_level.uncategorizedReports
      );
      setproductDetails(selectedUser.permissions_module_level.catalog);
      setprospectsPermi(selectedUser.permissions_module_level.prospects);
      setFbaShipmentPermi(selectedUser.permissions_module_level.fbaShipment);
      setContentPermi(selectedUser.permissions_module_level.content);
      setusersPermi(selectedUser.permissions_module_level.users);
      setOnboardingPermi(selectedUser.permissions_module_level.onboarding);
      setAMSPermi(selectedUser.permissions_module_level.ams);
      setAmsReportsPermi(selectedUser.permissions_module_level.amsReports);
      setAmazonAdvertisingPermi(selectedUser.permissions_module_level.amazonAdvertising);
      setWbrPermi(selectedUser.permissions_module_level.wbr);
    }
  }, [selectedUser]);

  const validateData = () => {
    var vendorsFlag = false;

    if (
      !salesPermi.allowed &&
      !biPermi.allowed &&
      !creativePermi.allowed &&
      !advertisingPermi.allowed &&
      !opsPermi.allowed &&
      !invoiceReconPermi.allowed &&
      !scAndCbPermi.allowed &&
      !amazonAdvertisingPermi.allowed &&
      !uncategorizedReports.allowed &&
      !productDetails.allowed &&
      !prospectsPermi.allowed &&
      !fbaShipmentPermi.allowed &&
      !amsReportsPermi.allowed &&
      !amazonAdvertisingPermi &&
      !wbrPermi &&
      !contentPermi.allowed &&
      !usersPermi.allowed &&
      !onboardingPermi.allowed &&
      !amsPermi.allowed &&
      !amsReportsPermi.allowed
    ) {
      toggleError("Grant atleast one Module");
      setTimeout(() => {
        toggleError(null);
      }, 2000);
    } else if (vendorsFlag) {
      toggleError("Vendor Details missing");
      setTimeout(() => {
        toggleError(null);
      }, 2000);
    } else {
      createUser();
    }
  };

  const createUser = () => {
    var postBody = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      orgName: orgName,
      orgPosition: orgPos,
      mobNumber: mobileNumber,
      permissions_module_level: {
        sales: salesPermi,
        businessIntelligence: biPermi,
        creative: creativePermi,
        advertising: advertisingPermi,
        operations: opsPermi,
        invoiceReconciliations: invoiceReconPermi,
        shortageclaimsAndChargebacks: scAndCbPermi,
        amazonAdvertising: amazonAdvertisingPermi,
        uncategorizedReports: uncategorizedReports,
        catalog: productDetails,
        prospects: prospectsPermi,
        fbaShipment: fbaShipmentPermi,
        content: contentPermi,
        users: usersPermi,
        onboarding: onboardingPermi,
        wbr: wbrPermi,
        ams: amsPermi,
        amsReports: amsReportsPermi,
      },
      userVendorConfigs: selectedVendorCodes.map((item) => item.vendorCode)
    };

    axios({
      method: "post",
      url: BASE_URL + api.updateUser,
      data: postBody,
      headers: tokenConfig(),
    })
      .then((res) => {
        closeModal();
        if (selectedUser.email === userState.userData.email) window.location.reload();
        toggleNotification(res.data.msg);
        setTimeout(() => {
          toggleNotification(null);
        }, 2000);
      })
      .catch((error) => console.log(error));
  };

  const updatePermissionsUser = (
    state,
    moduleName,
    isModuleLevelPermission,
    permissionsName,
    nested = false,
    subModule = null
  ) => {
    if (isModuleLevelPermission) {
      const stateStringified = JSON.stringify(state);
      const enableAll = JSON.parse(stateStringified.replaceAll("false", "true"));
      const disableAll = JSON.parse(stateStringified.replaceAll("true", "false"));
      moduleName((prevState) => ({
        ...(prevState.allowed ? { ...disableAll } : { ...enableAll } ),
      }));
      return;
    }

    if (nested) {
      moduleName((prevState) => ({
        ...prevState,
        categories: {
          ...prevState.categories,
          [subModule]: {
            ...prevState.categories[subModule],
            [permissionsName]: !prevState.categories[subModule][permissionsName]
          },
        },
      }));
    } else {
      moduleName((prevState) => ({
        ...prevState,
        categories: {
          ...prevState.categories,
          [permissionsName]: !prevState.categories[permissionsName],
        },
      }));
    }
  };

  return (
    <Dialog onClose={closeModal} open={showmodal} maxWidth="lg">
      <DialogContent>
        <form autoComplete="off">
          <Grid container spacing={4} style={{ padding: 30 }}>
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="h3">
                Account Information
              </Typography>
              <br />
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={email}
                onChange={(e) => setemail(e.target.value)}
                label="Email"
                variant="outlined"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="h3">
                Personal Information
              </Typography>
              <br />
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={firstName}
                onChange={(e) => setfirstName(e.target.value)}
                label="First Name"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={lastName}
                onChange={(e) => setlastName(e.target.value)}
                label="Last Name"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={orgName}
                onChange={(e) => setorgName(e.target.value)}
                label="Organisation Name"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={orgPos}
                onChange={(e) => setorgPos(e.target.value)}
                label="Organisation Position"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={mobileNumber}
                onChange={(e) => setmobileNumber(e.target.value)}
                label="Mobile Number"
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography color="textPrimary" variant="h3">
                    Modules
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textPrimary" variant="h3">
                    Permissions
                  </Typography>
                </Grid>
              </Grid>
              <br />
              <Divider />
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={salesPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          salesPermi,
                          setsalesPermi,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Sales"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={salesPermi.categories.shippedCOGS}
                      disabled={!salesPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          salesPermi,
                          setsalesPermi,
                          false,
                          "shippedCOGS"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Shipped COGs -VC"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={salesPermi.categories.allRatingsAndReview}
                      disabled={!salesPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          salesPermi,
                          setsalesPermi,
                          false,
                          "allRatingsAndReview"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="All Ratings & Reviews"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={salesPermi.categories.geographicInsights}
                      disabled={!salesPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          salesPermi,
                          setsalesPermi,
                          false,
                          "geographicInsights"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Geographic Insights"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={salesPermi.categories.goalsAndPeformance}
                      disabled={!salesPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          salesPermi,
                          setsalesPermi,
                          false,
                          "goalsAndPeformance"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Goals & Performance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={salesPermi.categories.ratingsAndReviews}
                      disabled={!salesPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          salesPermi,
                          setsalesPermi,
                          false,
                          "ratingsAndReviews"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Ratings & Reviews"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={salesPermi.categories.keyMetricsVC}
                      disabled={!salesPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          salesPermi,
                          setsalesPermi,
                          false,
                          "keyMetricsVC"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Key Metrics VC"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={salesPermi.categories.keyMetrics}
                      disabled={!salesPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          salesPermi,
                          setsalesPermi,
                          false,
                          "keyMetrics"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Key Metrics"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(biPermi, setbiPermi, true, null)
                      }
                      color="primary"
                    />
                  }
                  label="Business Intelligence"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.jsSegment}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "jsSegment"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="JS Segment"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.competitionBenchmarking}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "competitionBenchmarking"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Competition Benchmarking"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.productsAndBrands}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "productsAndBrands"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="JS Products & Brands"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.segments}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "segments"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Segments"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.bcg.bcgOverview}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "bcgOverview",
                          true,
                          "bcg"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="BCG Overview"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.bcg.bcgMatrix}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "bcgMatrix",
                          true,
                          "bcg"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="BCG Matrix"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.forecast.projections}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "projections",
                          true,
                          "forecast"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Projections"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.forecast.healthCheck}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "healthCheck",
                          true,
                          "forecast"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Health Check"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.forecast.segmentProjections}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "segmentProjections",
                          true,
                          "forecast"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Segment Projections"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        biPermi.categories.forecast.segmentForecastHealth
                      }
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "segmentForecastHealth",
                          true,
                          "forecast"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Segment Forecast Health"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.competitionDetailedSales}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "competitionDetailedSales"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Competition Detailed Sales"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.shareOfVoice}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "shareOfVoice"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Share of Voice"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={creativePermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          creativePermi,
                          setcreativePermi,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Creative"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={creativePermi.categories.detailedPage}
                      disabled={!creativePermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          creativePermi,
                          setcreativePermi,
                          false,
                          "detailedPage"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Detailed Pages"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={creativePermi.categories.aPlusPages}
                      disabled={!creativePermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          creativePermi,
                          setcreativePermi,
                          false,
                          "aPlusPages"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="A+ Pages"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={creativePermi.categories.seo}
                      disabled={!creativePermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          creativePermi,
                          setcreativePermi,
                          false,
                          "seo"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="SEO"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={creativePermi.categories.glanceViews}
                      disabled={!creativePermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          creativePermi,
                          setcreativePermi,
                          false,
                          "glanceViews"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Glance Views"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={creativePermi.categories.conversions}
                      disabled={!creativePermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          creativePermi,
                          setcreativePermi,
                          false,
                          "conversions"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Conversions"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={creativePermi.categories.productImages}
                      disabled={!creativePermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          creativePermi,
                          setcreativePermi,
                          false,
                          "productImages"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Product Images"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          advertisingPermi,
                          setadvertisingPermi,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Advertising"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advertisingPermi.categories.dayPartingDay}
                      disabled={!advertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          advertisingPermi,
                          setadvertisingPermi,
                          false,
                          "dayPartingDay"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Day Parting — Day"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advertisingPermi.categories.dayPartingHour}
                      disabled={!advertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          advertisingPermi,
                          setadvertisingPermi,
                          false,
                          "dayPartingHour"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Day Parting — Hour"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advertisingPermi.categories.dayPartingAsinsDay}
                      disabled={!advertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          advertisingPermi,
                          setadvertisingPermi,
                          false,
                          "dayPartingAsinsDay"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Day Parting — ASINs Day"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advertisingPermi.categories.dayPartingAsins}
                      disabled={!advertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          advertisingPermi,
                          setadvertisingPermi,
                          false,
                          "dayPartingAsins"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Day Parting — ASINs Hour"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advertisingPermi.categories.priceWar}
                      disabled={!advertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          advertisingPermi,
                          setadvertisingPermi,
                          false,
                          "priceWar"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Price War"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advertisingPermi.categories.productsNotServing}
                      disabled={!advertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          advertisingPermi,
                          setadvertisingPermi,
                          false,
                          "productsNotServing"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Products Not Serving"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={advertisingPermi.categories.CampaignsOutOfBudget}
                      disabled={!advertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          advertisingPermi,
                          setadvertisingPermi,
                          false,
                          "CampaignsOutOfBudget"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Campaigns out of Budget"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={opsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(opsPermi, setopsPermi, true, null)
                      }
                      color="primary"
                    />
                  }
                  label="Operations"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={opsPermi.categories.suppressedAsins}
                      disabled={!opsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          opsPermi,
                          setopsPermi,
                          false,
                          "suppressedAsins"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Search Suppressed ASINs"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={opsPermi.categories.eventNotifications}
                      disabled={!opsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          opsPermi,
                          setopsPermi,
                          false,
                          "eventNotifications"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Event Notifications"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={opsPermi.categories.sinventoryManagement}
                      disabled={!opsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          opsPermi,
                          setopsPermi,
                          false,
                          "sinventoryManagement"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Inventory Management"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={opsPermi.categories.fbaInventoryForecast}
                      disabled={!opsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          opsPermi,
                          setopsPermi,
                          false,
                          "fbaInventoryForecast"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Fulfilled by Amazon (FBA) Inventory Forecast"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={opsPermi.categories.threePReport}
                      disabled={!opsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          opsPermi,
                          setopsPermi,
                          false,
                          "threePReport"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="3P Report"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={opsPermi.categories.mapViolators}
                      disabled={!opsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          opsPermi,
                          setopsPermi,
                          false,
                          "mapViolators"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Map Violators"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={opsPermi.categories.strandedInventory}
                      disabled={!opsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          opsPermi,
                          setopsPermi,
                          false,
                          "strandedInventory"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Stranded Inventory"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={opsPermi.categories.accountHealth}
                      disabled={!opsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          opsPermi,
                          setopsPermi,
                          false,
                          "accountHealth"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Account Health"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={opsPermi.categories.fbaReimbursements}
                      disabled={!opsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          opsPermi,
                          setopsPermi,
                          false,
                          "fbaReimbursements"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="FBA Reimbursements"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={invoiceReconPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          invoiceReconPermi,
                          setinvoiceReconPermi,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Invoices Reconciliation"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        invoiceReconPermi.categories.sellerCentralInvoices
                      }
                      disabled={!invoiceReconPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          invoiceReconPermi,
                          setinvoiceReconPermi,
                          false,
                          "sellerCentralInvoices"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Seller Central Invoices"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        invoiceReconPermi.categories.vendorCentralInvoices
                      }
                      disabled={!invoiceReconPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          invoiceReconPermi,
                          setinvoiceReconPermi,
                          false,
                          "vendorCentralInvoices"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Vendor Central Invoices"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={scAndCbPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          scAndCbPermi,
                          setscAndCbPermi,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Shortageclaims & Chargebacks"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={scAndCbPermi.categories.sellerCentral}
                      disabled={!scAndCbPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          scAndCbPermi,
                          setscAndCbPermi,
                          false,
                          "sellerCentral"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Seller Central"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={scAndCbPermi.categories.vendorCentral}
                      disabled={!scAndCbPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          scAndCbPermi,
                          setscAndCbPermi,
                          false,
                          "vendorCentral"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Vendor Central"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Amazon Advertising"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        amazonAdvertisingPermi.categories
                          .settingsAndOptimisation.campaignsOptimisation
                      }
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "campaignsOptimisation",
                          true,
                          "settingsAndOptimisation"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Campaigns Optimisation"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        amazonAdvertisingPermi.categories
                          .settingsAndOptimisation.createCampaign
                      }
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "createCampaign",
                          true,
                          "settingsAndOptimisation"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Create Campaign"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        amazonAdvertisingPermi.categories
                          .settingsAndOptimisation.campaignsSettings
                      }
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "campaignsSettings",
                          true,
                          "settingsAndOptimisation"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Campaigns Settings"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        amazonAdvertisingPermi.categories
                          .settingsAndOptimisation.dayPartingRules
                      }
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "dayPartingRules",
                          true,
                          "settingsAndOptimisation"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Day Parting Rules"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        amazonAdvertisingPermi.categories.reports
                          .campaignPerformance
                      }
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "campaignPerformance",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Campaign Performance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        amazonAdvertisingPermi.categories.reports
                          .asinPerformance
                      }
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "asinPerformance",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="ASIN Performance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        amazonAdvertisingPermi.categories.reports
                          .keywordPerformance
                      }
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "keywordPerformance",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Keyword Performance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        amazonAdvertisingPermi.categories.reports
                          .searchTermPerformance
                      }
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "searchTermPerformance",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Search Term Performance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        amazonAdvertisingPermi.categories.reports
                          .targetPerformance
                      }
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "targetPerformance",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Targeting Performance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        amazonAdvertisingPermi.categories.reports.budgetChanges
                      }
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "budgetChanges",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Budget Changes"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        amazonAdvertisingPermi.categories.reports
                          .placementAnalysis
                      }
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "placementAnalysis",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Placement Analysis"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        amazonAdvertisingPermi.categories.reports.reachTrend
                      }
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "reachTrend",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Reach Trend"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        amazonAdvertisingPermi.categories.amsPPCAudit
                      }
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "amsPPCAudit"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="AMS PPC Audit Leads"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        amazonAdvertisingPermi.categories.amsForecast
                      }
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "amsForecast"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="AMS Forecast"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={uncategorizedReports.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          uncategorizedReports,
                          setuncategorizedReports,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Uncategorised Reports"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        uncategorizedReports.categories.brandSegmentsByDay
                      }
                      disabled={!uncategorizedReports.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          uncategorizedReports,
                          setuncategorizedReports,
                          false,
                          "brandSegmentsByDay"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Brand Segments By Day"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={uncategorizedReports.categories.keywordsByAsin}
                      disabled={!uncategorizedReports.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          uncategorizedReports,
                          setuncategorizedReports,
                          false,
                          "keywordsByAsin"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Keywords By ASIN"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={uncategorizedReports.categories.salesAndRank}
                      disabled={!uncategorizedReports.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          uncategorizedReports,
                          setuncategorizedReports,
                          false,
                          "salesAndRank"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Sales and Rank"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={uncategorizedReports.categories.amsScreenOne}
                      disabled={!uncategorizedReports.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          uncategorizedReports,
                          setuncategorizedReports,
                          false,
                          "amsScreenOne"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="AMS Screen 1"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={uncategorizedReports.categories.amsScreenTwo}
                      disabled={!uncategorizedReports.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          uncategorizedReports,
                          setuncategorizedReports,
                          false,
                          "amsScreenTwo"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="AMS Screen 2"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productDetails.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setproductDetails,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Catalog"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productDetails.categories.productDetails}
                      disabled={!productDetails.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setproductDetails,
                          false,
                          "productDetails"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Product Details"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productDetails.categories.productHiResImages}
                      disabled={!productDetails.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setproductDetails,
                          false,
                          "productHiResImages"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Product Hi-Res Images"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productDetails.categories.asingSkuMapping}
                      disabled={!productDetails.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setproductDetails,
                          false,
                          "asingSkuMapping"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="ASIN SKU Mapping"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productDetails.categories.uploadHistoricData}
                      disabled={!productDetails.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setproductDetails,
                          false,
                          "uploadHistoricData"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Upload Historic Data"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productDetails.categories.cohesity}
                      disabled={!productDetails.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setproductDetails,
                          false,
                          "cohesity"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Cohesity"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productDetails.categories.updateItem}
                      disabled={!productDetails.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setproductDetails,
                          false,
                          "updateItem"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Update Item"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productDetails.categories.catalogItems}
                      disabled={!productDetails.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setproductDetails,
                          false,
                          "catalogItems"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Catalog Items"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productDetails.categories.catalogActivity}
                      disabled={!productDetails.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setproductDetails,
                          false,
                          "catalogActivity"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Catalog Activity"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productDetails.categories.cohesityLogs}
                      disabled={!productDetails.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setproductDetails,
                          false,
                          "cohesityLogs"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Cohesity Logs"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productDetails.categories.detailPage}
                      disabled={!productDetails.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setproductDetails,
                          false,
                          "detailPage"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Detail Page"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productDetails.categories.seoUpdateCheck}
                      disabled={!productDetails.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setproductDetails,
                          false,
                          "seoUpdateCheck"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="SEO Update Check"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productDetails.categories.catalogImageManager}
                      disabled={!productDetails.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setproductDetails,
                          false,
                          "catalogImageManager"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Catalog Image Manager"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productDetails.categories.catalogVariations}
                      disabled={!productDetails.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setproductDetails,
                          false,
                          "catalogVariations"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Catalog Variations"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={prospectsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          prospectsPermi,
                          setprospectsPermi,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Apollo"
                />
              </Grid>
              <Grid item xs={6}>
              <FormControlLabel
                  control={
                    <Checkbox
                      checked={prospectsPermi.categories.categoryScrapping}
                      disabled={!prospectsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setprospectsPermi,
                          false,
                          "categoryScrapping"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Category ASINs"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={prospectsPermi.categories.brandFinder}
                      disabled={!prospectsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          productDetails,
                          setprospectsPermi,
                          false,
                          "brandFinder"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Brand Finder"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={prospectsPermi.categories.blockDomains}
                      disabled={!prospectsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          prospectsPermi,
                          setprospectsPermi,
                          false,
                          "blockDomains"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Block Domains"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={prospectsPermi.categories.contactFinder}
                      disabled={!prospectsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          prospectsPermi,
                          setprospectsPermi,
                          false,
                          "contactFinder"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Contact Finder"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={prospectsPermi.categories.listBrands}
                      disabled={!prospectsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          prospectsPermi,
                          setprospectsPermi,
                          false,
                          "listBrands"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="List Brands"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={prospectsPermi.categories.listContacts}
                      disabled={!prospectsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          prospectsPermi,
                          setprospectsPermi,
                          false,
                          "listContacts"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="List Contacts"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={prospectsPermi.categories.listEmptyDomains}
                      disabled={!prospectsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          prospectsPermi,
                          setprospectsPermi,
                          false,
                          "listEmptyDomains"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="List Empty Domains"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={prospectsPermi.categories.brandFinderGlobal}
                      disabled={!prospectsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          prospectsPermi,
                          setprospectsPermi,
                          false,
                          "brandFinderGlobal"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Brand Finder Global"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={prospectsPermi.categories.searchContacts}
                      disabled={!prospectsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          prospectsPermi,
                          setprospectsPermi,
                          false,
                          "searchContacts"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Search Contacts"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={prospectsPermi.categories.searchOrganisations}
                      disabled={!prospectsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          prospectsPermi,
                          setprospectsPermi,
                          false,
                          "searchOrganisations"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Search Organisations"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={prospectsPermi.categories.enrichContacts}
                      disabled={!prospectsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          prospectsPermi,
                          setprospectsPermi,
                          false,
                          "enrichContacts"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Enrich Contacts"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={prospectsPermi.categories.domainsLLMValidation}
                      disabled={!prospectsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          prospectsPermi,
                          setprospectsPermi,
                          false,
                          "domainsLLMValidation"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Domains LLM Validation"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fbaShipmentPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          fbaShipmentPermi,
                          setFbaShipmentPermi,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="FBA Shipment"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fbaShipmentPermi.categories.manageShipments}
                      disabled={!fbaShipmentPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          fbaShipmentPermi,
                          setFbaShipmentPermi,
                          false,
                          "manageShipments"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Manage Shipments"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fbaShipmentPermi.categories.createNewShipment}
                      disabled={!fbaShipmentPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          fbaShipmentPermi,
                          setFbaShipmentPermi,
                          false,
                          "createNewShipment"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Create New Shipment"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fbaShipmentPermi.categories.estimateShippingCost}
                      disabled={!fbaShipmentPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          fbaShipmentPermi,
                          setFbaShipmentPermi,
                          false,
                          "estimateShippingCost"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Estimate Shipping Cost"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fbaShipmentPermi.categories.printLabelsOrBol}
                      disabled={!fbaShipmentPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          fbaShipmentPermi,
                          setFbaShipmentPermi,
                          false,
                          "printLabelsOrBol"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Print Labels or BOL"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fbaShipmentPermi.categories.sendTransportDetails}
                      disabled={!fbaShipmentPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          fbaShipmentPermi,
                          setFbaShipmentPermi,
                          false,
                          "sendTransportDetails"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Send Transport Details"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fbaShipmentPermi.categories.shipmentAddress}
                      disabled={!fbaShipmentPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          fbaShipmentPermi,
                          setFbaShipmentPermi,
                          false,
                          "shipmentAddress"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Shipment Address"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fbaShipmentPermi.categories.casePackDetails}
                      disabled={!fbaShipmentPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          fbaShipmentPermi,
                          setFbaShipmentPermi,
                          false,
                          "casePackDetails"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Case Pack Details"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fbaShipmentPermi.categories.shipmentContact}
                      disabled={!fbaShipmentPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          fbaShipmentPermi,
                          setFbaShipmentPermi,
                          false,
                          "shipmentContact"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Shipment Contact"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fbaShipmentPermi.categories.sendTrackingNumbers}
                      disabled={!fbaShipmentPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          fbaShipmentPermi,
                          setFbaShipmentPermi,
                          false,
                          "sendTrackingNumbers"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Send Tracking Numbers"
                />
                <Divider />
              </Grid>
            </Grid>

            
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contentPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          contentPermi,
                          setContentPermi,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Content Revisions"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contentPermi.categories.approveContent}
                      disabled={!contentPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          contentPermi,
                          setContentPermi,
                          false,
                          "approveContent"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Approve Content"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contentPermi.categories.updateContent}
                      disabled={!contentPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          contentPermi,
                          setContentPermi,
                          false,
                          "updateContent"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Update Content"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contentPermi.categories.changelog}
                      disabled={!contentPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          contentPermi,
                          setContentPermi,
                          false,
                          "changelog"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Changelog"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={usersPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          usersPermi,
                          setusersPermi,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Users"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={usersPermi.categories.usersAdd}
                      disabled={!usersPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          usersPermi,
                          setusersPermi,
                          false,
                          "usersAdd"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Users Add"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={usersPermi.categories.usersEdit}
                      disabled={!usersPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          usersPermi,
                          setusersPermi,
                          false,
                          "usersEdit"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Users Edit"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={usersPermi.categories.usersList}
                      disabled={!usersPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          usersPermi,
                          setusersPermi,
                          false,
                          "usersList"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Users List"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onboardingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          onboardingPermi,
                          setOnboardingPermi,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Onboarding"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onboardingPermi.categories.cronsManagement}
                      disabled={!onboardingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          onboardingPermi,
                          setOnboardingPermi,
                          false,
                          "cronsManagement"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Crons Management"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onboardingPermi.categories.uploadHistoricData}
                      disabled={!onboardingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          onboardingPermi,
                          setOnboardingPermi,
                          false,
                          "uploadHistoricData"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Historic Data"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onboardingPermi.categories.jsSegment}
                      disabled={!onboardingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          onboardingPermi,
                          setOnboardingPermi,
                          false,
                          "jsSegment"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="JS Segment"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onboardingPermi.categories.amsOnboarding}
                      disabled={!onboardingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          onboardingPermi,
                          setOnboardingPermi,
                          false,
                          "amsOnboarding"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="AMS Onboarding"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onboardingPermi.categories.dapiStatistics}
                      disabled={!onboardingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          onboardingPermi,
                          setOnboardingPermi,
                          false,
                          "dapiStatistics"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="D API Statistics"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onboardingPermi.categories.onboardingASINs}
                      disabled={!onboardingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          onboardingPermi,
                          setOnboardingPermi,
                          false,
                          "onboardingASINs"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="ASINs"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onboardingPermi.categories.dbArchives}
                      disabled={!onboardingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          onboardingPermi,
                          setOnboardingPermi,
                          false,
                          "dbArchives"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Database Archives"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onboardingPermi.categories.customerSettings}
                      disabled={!onboardingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          onboardingPermi,
                          setOnboardingPermi,
                          false,
                          "customerSettings"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Customer Settings"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onboardingPermi.categories.reportsHealth}
                      disabled={!onboardingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          onboardingPermi,
                          setOnboardingPermi,
                          false,
                          "reportsHealth"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Reports Health"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onboardingPermi.categories.deactivateAccount}
                      disabled={!onboardingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          onboardingPermi,
                          setOnboardingPermi,
                          false,
                          "deactivateAccount"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Deactivate Account"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onboardingPermi.categories.mergeAccount}
                      disabled={!onboardingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          onboardingPermi,
                          setOnboardingPermi,
                          false,
                          "mergeAccount"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Merge Account"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onboardingPermi.categories.userGroups}
                      disabled={!onboardingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          onboardingPermi,
                          setOnboardingPermi,
                          false,
                          "userGroups"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="User Groups"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        onboardingPermi.categories.customerAccounts
                      }
                      disabled={!onboardingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          onboardingPermi,
                          setOnboardingPermi,
                          false,
                          "customerAccounts",
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Customer Accounts"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(amsPermi, setAMSPermi, true, null)
                      }
                      color="primary"
                    />
                  }
                  label="AMS"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsPermi.categories.createCampaign}
                      disabled={!amsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsPermi,
                          setAMSPermi,
                          false,
                          "createCampaign"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Create Campaign"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsPermi.categories.campaigns}
                      disabled={!amsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsPermi,
                          setAMSPermi,
                          false,
                          "campaigns"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="AMS Campaigns"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsPermi.categories.vendorSettings}
                      disabled={!amsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsPermi,
                          setAMSPermi,
                          false,
                          "vendorSettings"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Vendor Settings"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsPermi.categories.salesAnalysis}
                      disabled={!amsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsPermi,
                          setAMSPermi,
                          false,
                          "salesAnalysis"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Sales Analysis"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsPermi.categories.topPerformingKeywords}
                      disabled={!amsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsPermi,
                          setAMSPermi,
                          false,
                          "topPerformingKeywords"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Top Performing Keywords"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsPermi.categories.reachTrend}
                      disabled={!amsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsPermi,
                          setAMSPermi,
                          false,
                          "reachTrend"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Reach Trend"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsPermi.categories.amsDayParting}
                      disabled={!amsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsPermi,
                          setAMSPermi,
                          false,
                          "amsDayParting"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="AMS Day Parting"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsPermi.categories.budgetChanges}
                      disabled={!amsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsPermi,
                          setAMSPermi,
                          false,
                          "budgetChanges"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Budget Changes"
                />
                <Divider />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsReportsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsReportsPermi,
                          setAmsReportsPermi,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="AMS Reports"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsReportsPermi.categories.campaignPerformance}
                      disabled={!amsReportsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsReportsPermi,
                          setAmsReportsPermi,
                          false,
                          "campaignPerformance"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Campaign Performance"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsReportsPermi.categories.asinPerformance}
                      disabled={!amsReportsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsReportsPermi,
                          setAmsReportsPermi,
                          false,
                          "asinPerformance"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="ASIN Performance"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsReportsPermi.categories.keywordPerformance}
                      disabled={!amsReportsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsReportsPermi,
                          setAmsReportsPermi,
                          false,
                          "keywordPerformance"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Keyword Performance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsReportsPermi.categories.searchTermPerformance}
                      disabled={!amsReportsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsReportsPermi,
                          setAmsReportsPermi,
                          false,
                          "searchTermPerformance"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Search Term Performance"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsReportsPermi.categories.targetPerformance}
                      disabled={!amsReportsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsReportsPermi,
                          setAmsReportsPermi,
                          false,
                          "targetPerformance"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Targeting Performance"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsReportsPermi.categories.budgetChanges}
                      disabled={!amsReportsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsReportsPermi,
                          setAmsReportsPermi,
                          false,
                          "budgetChanges"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Budget Changes"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsReportsPermi.categories.placementAnalysis}
                      disabled={!amsReportsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsReportsPermi,
                          setAmsReportsPermi,
                          false,
                          "placementAnalysis"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Placement Analysis"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amsReportsPermi.categories.reachTrend}
                      disabled={!amsReportsPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amsReportsPermi,
                          setAmsReportsPermi,
                          false,
                          "reachTrend"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Reach Trend"
                />
              <Divider />
            </Grid>
          </Grid>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wbrPermi?.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          wbrPermi,
                          setWbrPermi,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="WBR"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wbrPermi?.categories?.actionItems}
                      disabled={!wbrPermi?.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          wbrPermi,
                          setWbrPermi,
                          false,
                          "actionItems"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Action Items"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wbrPermi?.categories?.keyOutputMetrics}
                      disabled={!wbrPermi?.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          wbrPermi,
                          setWbrPermi,
                          false,
                          "keyOutputMetrics"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Key Output Metrics"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wbrPermi?.categories?.keyInputMetrics}
                      disabled={!wbrPermi?.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          wbrPermi,
                          setWbrPermi,
                          false,
                          "keyInputMetrics"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Key Input Metrics"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wbrPermi?.categories?.financeMetrics}
                      disabled={!wbrPermi?.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          wbrPermi,
                          setWbrPermi,
                          false,
                          "financeMetrics"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Finance Metrics"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wbrPermi?.categories?.ams}
                      disabled={!wbrPermi?.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          wbrPermi,
                          setWbrPermi,
                          false,
                          "ams"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="AMS"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wbrPermi?.categories?.operationMetrics}
                      disabled={!wbrPermi?.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          wbrPermi,
                          setWbrPermi,
                          false,
                          "operationMetrics"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Operations Metrics"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wbrPermi?.categories?.amRules}
                      disabled={!wbrPermi?.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          wbrPermi,
                          setWbrPermi,
                          false,
                          "amRules"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="AM Rules"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wbrPermi?.categories?.weeklyInsights}
                      disabled={!wbrPermi?.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          wbrPermi,
                          setWbrPermi,
                          false,
                          "weeklyInsights"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Weekly Insights"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wbrPermi?.categories?.funnel}
                      disabled={!wbrPermi?.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          wbrPermi,
                          setWbrPermi,
                          false,
                          "funnel"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Sales Conversion Funnel"
                />
                <Divider />
              </Grid>
            </Grid>


            <Grid item xs={12}>
              <Typography color="textPrimary" variant="h3">
                Vendors
              </Typography>
              <br />
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">Vendors</InputLabel>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  value={""}
                  placeholder="Add Vendor"
                  input={<Input id="select-multiple-chip" />}
                >
                  {customerConfigs.filter((config) => selectedVendorCodes.map((item) => item.vendorCode).includes(config.vendorCode) === false).map((vendor) => (
                    <MenuItem
                      key={vendor.vendorCode}
                      value={vendor.vendorCode}
                      onClick={() => setSelectedVendorCodes([...selectedVendorCodes, vendor])}
                    >
                      {vendor.customerName + " - " + vendor.vendorCode}
                    </MenuItem>
                  ))}
                </Select>
                {customerConfigs.filter((config) => selectedVendorCodes.map((item) => item.vendorCode).includes(config.vendorCode) === false).length === 0 &&
                  <Typography variant="caption">
                    {"All vendors selected, no more vendors to show"}
                  </Typography>
                }
              </FormControl>
              <div style={{ display: "flex", gap: "8px", padding: "4px 0px" }}>
                <Button color="primary" variant="contained" size="small" onClick={() => setSelectedVendorCodes([...customerConfigs])}>
                  Add all vendors
                </Button>
                {selectedVendorCodes.length > 0 &&
                  <Button color="primary" variant="outlined" size="small" onClick={() => setSelectedVendorCodes([])}>
                    Clear vendors
                  </Button>
                }
              </div>
              <div className={classes.chips}>
                {selectedVendorCodes.map((value) => (
                  <Chip
                    style={{ margin: "5px" }}
                    key={value.vendorCode}
                    label={(value.customerName || value.vendorName) + " - " + value.vendorCode}
                    className={classes.chip}
                    onDelete={() => setSelectedVendorCodes([...selectedVendorCodes.filter(item => item.vendorCode !== value.vendorCode)])}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={validateData}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
